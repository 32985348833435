import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import './Results.css';
import field from '../assets/field.png';

const AllResults = () => {
    const [matches, setMatches] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [selectedLeague, setSelectedLeague] = useState(null);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [filteredMatches, setFilteredMatches] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar`);
                const matchData = response.data.filter(match => match.is_it_fixture === false);

                // Her maç için takım bilgilerini çekmek üzere sorgular oluştur
                const teamRequests = matchData.map(async (match) => {
                    const firstTeamPromise = axios.get(
                        `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-name/${formatTeamNameForURL(match.first_team_name)}`
                    );
                    const secondTeamPromise = axios.get(
                        `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-name/${formatTeamNameForURL(match.second_team_name)}`
                    );

                    const [firstTeamResponse, secondTeamResponse] = await Promise.all([
                        firstTeamPromise,
                        secondTeamPromise,
                    ]);

                    return {
                        ...match,
                        first_team: firstTeamResponse.data,
                        second_team: secondTeamResponse.data,
                    };
                });
                const matchesWithTeams = await Promise.all(teamRequests);

                setMatches(matchesWithTeams);

                const seasonsResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar");
                setSeasons(seasonsResponse.data);

                const leaguesResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler");
                setLeagues(leaguesResponse.data);
            } catch (error) {
                console.error("Hata:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedLeague && selectedSeason) {
            const filtered = matches.filter(
                (match) =>
                    match.league_name === selectedLeague &&
                    match.match_season === selectedSeason &&
                    match.is_it_fixture === false
            );
            setFilteredMatches(filtered);
        } else {
            setFilteredMatches([]); // Seçim olmadığında boş liste
        }
    }, [selectedLeague, selectedSeason, matches]);

    const handleLeagueChange = (leagueName) => {
        setSelectedLeague(leagueName);
        setSelectedSeason(null); // Lig değiştiğinde sezonu sıfırla
    };

    const handleSeasonChange = (seasonId) => {
        setSelectedSeason(seasonId);
    };


    return (
        <div>
            <Header />
            <header class="header-banner">
                <h1 class="banner-title">Maç Sonuçları</h1>
                <div class="zigzag">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#4facfe" fill-opacity="1" d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,96C672,128,768,192,864,213.3C960,235,1056,213,1152,213.3C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                    </svg>
                </div>
            </header>
            <div className='centered-container-2'>
                <select onChange={(e) => handleLeagueChange(e.target.value)}>
                    <option value="">Lig Seç</option>
                    {leagues.map((league) => (
                        <option key={league.id} value={league.league_name}>
                            {league.league_name}
                        </option>
                    ))}
                </select>

                {selectedLeague && (
                    <select onChange={(e) => handleSeasonChange(e.target.value)}>
                        <option value="">Sezon Seç</option>
                        {seasons
                            .filter((season) => season.league_name === selectedLeague)
                            .map((season) => (
                                <option key={season.id} value={season.season_name}>
                                    {season.season_name}
                                </option>
                            ))}
                    </select>
                )}
            </div>

            {selectedLeague && selectedSeason && (
                <div >
                    <MatchTable matches={filteredMatches}/>
                </div>
            )}

            <Footer />
        </div>
    );
};

const MatchTable = ({ matches }) => {
    const groupMatchesByDate = (matches) => {
        return matches.reduce((acc, match) => {
            const matchDate = formatDate(match.date); // formatDate'i kullanarak tarih gruplandırma
            if (!acc[matchDate]) {
                acc[matchDate] = [];
            }
            acc[matchDate].push(match);
            return acc;
        }, {});
    };

    const formatDate = (isoDate) => {
        if (!isoDate) {
            return "";
        }
        const months = [
            "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
            "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
        ];

        const date = new Date(isoDate); // Tarih objesi oluştur
        const day = date.getDate(); // Gün
        const month = months[date.getMonth()]; // Ay (yazıyla)
        const year = date.getFullYear(); // Yıl

        return `${day} ${month} ${year}`;
    };

    const formatTime = (timeString) => {
        const time = timeString.slice(0, 5);
        return `${time}`;
    };

    const groupedMatches = groupMatchesByDate(matches);

    return (
        <div className="fixture-container">
            {Object.keys(groupedMatches) // Gruplandırılmış tarihleri sırala
                .map((date) => (
                    <React.Fragment key={date}>
                        {/* Tarih Başlığı */}
                        <h2 className="fixture-date">{date}</h2>

                        {/* Maçlar */}
                        {groupedMatches[date]
                            .sort((a, b) => new Date(`1970-01-01T${a.time}`) - new Date(`1970-01-01T${b.time}`))
                            .map((match) => (
                                <div className="fixture-row" key={match.id}>
                                    {/* Birinci Takım */}
                                    <div className="team">
                                        <span>{match.first_team_name}</span>
                                        <img
                                            src={match.first_team.logo}
                                            alt={match.first_team_name}
                                            className="team-logo"
                                        />
                                    </div>

                                    {/* Maç Saati */}
                                    <div className="time">{match.first_team_score}-{match.second_team_score}</div>

                                    {/* İkinci Takım */}
                                    <div className="team">
                                        <img
                                            src={match.second_team.logo}
                                            alt={match.second_team_name}
                                            className="team-logo"
                                        />
                                        <span>{match.second_team_name}</span>
                                    </div>

                                    {/* Ek Bilgiler */}
                                    <div className="stadium">
                                        <img
                                            src={field}
                                            alt={match.match_field}
                                            className="team-logo"
                                        />
                                        <span><a
                                            href={`https://www.google.com/maps?q=${encodeURIComponent("100. Yıl Bulvarı, 1245. Sk. No:9, 06170 Yenimahalle")}`}
                                            target="_blank" /* Yeni sekmede aç */
                                            rel="noopener noreferrer" /* Güvenlik için */
                                            className="stadium-link"
                                        >{match.match_field}</a></span>
                                    </div>
                                    <div className="actions">
                                        <button
                                            className="quick-view"
                                            onClick={() => (window.location.href = `/maclar/${match.id}`)}
                                        >
                                            Maçı Görüntüle
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </React.Fragment>
                ))}
        </div>

    );
};

function formatTeamNameForURL(teamName) {
    return teamName
        .toLowerCase()
        .replace(/ç/g, "c")
        .replace(/ğ/g, "g")
        .replace(/ı/g, "i")
        .replace(/ö/g, "o")
        .replace(/ş/g, "s")
        .replace(/ü/g, "u")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
        .replace(/\s+/g, "-");
}

export default AllResults;
