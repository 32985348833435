import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Try from './pages/try';
import PlayerList from './pages/Player_List';

import Rules from './pages/Rules';
import PlayerListDouble from './pages/PlayerListDouble';
import News from './pages/News';
import Results from './pages/Results';
import Matches from './pages/MatchPage';
import Players from './pages/Player';
import Teams from './pages/Team';
import Login from './pages/Login';
import Communication from './pages/Communication';
import Transfers from './pages/Transfers';
import Penalties from './pages/Penalties';
import Rewards from './pages/Rewards';
import TeamList from './pages/TeamList';
import AllTeamList from './pages/AllTeamList';
import LeagueStandings from './pages/LeagueStandings';
import Fixture from './pages/Fixture';
import AllResults from './pages/AllResults';

//EDITOR PAGES
import EditorPlayers from './editor_pages/Editor_Players';
import EditorTeams from './editor_pages/Editor_Teams';
import EditorMatches from './editor_pages/Editor_Matches';
import EditorLeagues from './editor_pages/Editor_Leagues';
import EditorSeasons from './editor_pages/Editor_Seasons';

import Editor from './editor_pages/Editor';
import EditorMenu from './editor_pages/EditorMenu';
import UpdateTeam from './editor_pages/Update_Team';
import UpdatePlayer from './editor_pages/Update_Player';
import UpdateMatch from './editor_pages/Update_Match';
import EnterStats from './editor_pages/Enter_Stats';
import Add_Writings from './editor_pages/Add_Writings';
import AddMatch from './editor_pages/Add_Match';
import AddUser from './editor_pages/Add_User';


import React, { createContext, useState,useEffect } from 'react';
import axios from 'axios';

import ProtectedRoute from './ProtectedRoute'; // Adjust path as necessary
import api from './api/api';
// Kullanıcı Context'i
export const UserContext = createContext();
axios.defaults.withCredentials = true;


function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}



function AppContent() {
  const location = useLocation();

  const [user, setUser] = useState(null); // Kullanıcı bilgisini tutar
  const [isLoading, setIsLoading] = useState(true); // Yüklenme durumunu ekleyin
  // Verify session on app load or route change
  useEffect(() => {
    const verifySession = async () => {
      try {
        

        const response = await api.get('/users/verify'); // `api` kullan

        const { user } = response.data;
        setUser(user); // Set user state

      } catch (error) {
        console.error('Oturum doğrulama hatası:', error);
        setUser(null); // Clear user if session is invalid
      }finally {
        setIsLoading(false); // Yüklenme tamamlandı
      }
    };

  verifySession();
}, [location]); // Run on location change to ensure session consistency

  // Menü barının gösterileceği sayfalar
  const editorPages = [
    '/editor',
    '/editor/oyuncular',
    '/editor/takimlar',
    '/editor/maclar',
    '/editor/ligler',
    '/editor/sezonlar',
    '/editor/mac-ekle',
    '/editor/yazi-ekle',
    '/editor/takim-guncelle',
    '/editor/oyuncu-guncelle',
    '/editor/kullanici-ekle'
  ];
  const dynamicPaths = ['/editor/mac-guncelle/','/editor/ist-gir/'];
  const showEditorMenu = editorPages.includes(location.pathname) ||
  dynamicPaths.some(path => location.pathname.startsWith(path));

  return (
    <UserContext.Provider value={{ user, setUser }}>
    <div className="app-container">
      {showEditorMenu && <EditorMenu />}
      <div className="content">

      {isLoading ? ( // Yükleniyor durumunu burada kontrol edin
          <div>Loading...</div>
        ) : (
        <Routes>
          {/* FRONTEND SAYFALARI */}
          <Route path="/" element={<Home />} />
          <Route path="/try" element={<Try />} />
          <Route path="/oyuncu-listesi" element={<PlayerList />} />
          <Route path="/kurallar" element={<Rules />} />
          <Route path="/en-degerliler-gol-kralligi" element={<PlayerListDouble />} />
          <Route path="/haberler" element={<News />} />
          <Route path='/puan-durumu' element={<LeagueStandings/>} />
          <Route path='/takim-listesi' element={<AllTeamList/>} />
          <Route path="/fikstur" element={<Fixture />} />
          <Route path="/sonuclar" element={<AllResults />} />

          <Route path='/kurallar' element={<Rules/>} />
          <Route path='/iletisim' element={<Communication/>} /> {/* not completed */}
          <Route path='/transferler' element={<Transfers/>} /> {/* not completed */}
          <Route path='/cezalar' element={<Penalties/>} /> {/* not completed */}

          <Route path='/en-degerliler-gol-kralligi' element={<PlayerListDouble/>} />
          <Route path='/haberler-ve-duyurular' element={<News/>} /> {/* not completed */}
          <Route path='/odul-kosesi' element={<Rewards/>} /> {/* not completed */}
          <Route path='/:leagueName/sonuclar' element={<Results/>} />
          <Route path='/:leagueName/puan-durumu' element={<TeamList/>} />
          <Route path="/takimlar/:teamName" element={<Teams />} />
          <Route path="/maclar/:matchID" element={<Matches />} />
          <Route path="/oyuncular/:playerID" element={<Players />} />

          <Route path="/giris" element={<Login />} />

          {/* Editor Sayfaları */}
          {/* Protected Routes */}
        
          <Route
            path="/editor"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']} >
                <Editor />
              </ProtectedRoute>
            }
          />

          <Route
            path="/editor/oyuncular"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <EditorPlayers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/takimlar"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <EditorTeams />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/maclar"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <EditorMatches />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/ligler"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <EditorLeagues />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/sezonlar"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <EditorSeasons />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/mac-ekle"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <AddMatch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/mac-guncelle/:matchId"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <UpdateMatch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/ist-gir/:matchId"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <EnterStats />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/takim-guncelle"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <UpdateTeam />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/oyuncu-guncelle/:matchId"
            element={
              <ProtectedRoute user={user} roleRequired={['editor', 'admin']}>
                <UpdatePlayer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/yazi-ekle"
            element={
              <ProtectedRoute user={user} roleRequired={['admin']}>
                <Add_Writings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editor/kullanici-ekle"
            element={
              <ProtectedRoute user={user} roleRequired={['admin']}>
                <AddUser />
              </ProtectedRoute>
            }
          />

        </Routes>
        )}
      </div>
    </div>
    </UserContext.Provider>
  );
}

export default App;
