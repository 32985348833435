import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Try = () => {
    const [matches, setMatches] = useState([]);

    const uploadPhoto = async (matchId, photoFile) => {
        const formData = new FormData();
        formData.append('photo', photoFile);

        try {
            const response = await axios.post(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchId}/photo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (error) {
            console.error("Fotoğraf yüklenirken hata:", error);
        }
    };

    const handleFileChange = (event, matchId) => {
        const file = event.target.files[0];
        if (file) {
            uploadPhoto(matchId, file);
        }
    };

    useEffect(() => {
        const fetchLastFiveMatches = async () => {
            try {
                const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/last-five");
                setMatches(response.data);
            } catch (error) {
                console.error("Hata:", error);
            }
        };

        fetchLastFiveMatches();
    }, []);

    return (
        <div>
            {matches.map((match) => (
                <div key={match.id}>
                    <h3>{match.name}</h3>
                    <input
                        type="file"
                        onChange={(event) => handleFileChange(event, match.id)}
                    />
                </div>
            ))}
        </div>
    );
};

export default Try;
