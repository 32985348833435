import Header from './Header';
import Footer from './Footer';
import './Team.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import defaultPlayerImage from '../assets/defaultPlayer.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import field from '../assets/field.png';

import { useParams } from 'react-router-dom';

const TeamPage = () => {
    const { teamName } = useParams();
    const [team, setTeam] = useState([]);
    const [Players, setPlayers] = useState([]);
    const [Matches, setMatches] = useState([]);
    const [activeTab, setActiveTab] = useState("stats");

    useEffect(() => {
        const fetchTeam = async () => {
            try {
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-name/${teamName}`);
                const teamId = response.data.id;

                const playersResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/${teamId}`);
                const playerIds = playersResponse.data;

                const playerDetailsPromises = playerIds.map(async (player) => {
                    const Id = player.player_id;
                    const playerResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${Id}`);
                    return playerResponse.data[0];
                });

                const playerDetails = await Promise.all(playerDetailsPromises);

                setTeam(response.data);
                setPlayers(playerDetails);
            } catch (error) {
                console.error("Hata:", error);
            }
        };
        fetchTeam();

        const fetchMatches = async () => {
            try {
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar`);
                const teamRequests = response.data.map(async (match) => {
                    const firstTeamPromise = axios.get(
                        `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-name/${formatTeamNameForURL(match.first_team_name)}`
                    );
                    const secondTeamPromise = axios.get(
                        `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-name/${formatTeamNameForURL(match.second_team_name)}`
                    );

                    const [firstTeamResponse, secondTeamResponse] = await Promise.all([
                        firstTeamPromise,
                        secondTeamPromise,
                    ]);

                    return {
                        ...match,
                        first_team: firstTeamResponse.data,
                        second_team: secondTeamResponse.data,
                    };
                });
                const matchesWithTeams = await Promise.all(teamRequests);

                setMatches(matchesWithTeams);
            } catch (error) {
                console.error("Hata:", error);
            }
        };
        fetchMatches();
    }, []);

    const formatDate = (isoDate) => {
        const months = [
            "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
            "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
        ];

        const date = new Date(isoDate); // Tarih objesi oluştur
        const day = date.getDate(); // Gün
        const month = months[date.getMonth()]; // Ay (yazıyla)
        const year = date.getFullYear(); // Yıl

        return `${day} ${month} ${year}`;
    };

    return (
        <div>
            <Header />

            <div className="team-profile">
                <header className="team-header">
                    <div className="team-logo-img">
                        <img src={team.logo} alt={team.team_name} />
                    </div>
                    <div className="team-name-info">
                        <h1>{team.team_name}</h1>
                        <p>Est: {formatDate(team.attendence_date)}</p>
                    </div>
                </header>

                <div className="team-information">
                    <div className="buttons">
                        {/* <div className="button-overview">
                            <button
                                className={activeTab === "overview" ? "active" : ""}
                                onClick={() => setActiveTab("overview")}
                            >
                                Overview
                            </button>
                        </div> */}
                        <div className="button-stat">
                            <button
                                className={activeTab === "stats" ? "active" : ""}
                                onClick={() => setActiveTab("stats")}
                            >
                                Stats
                            </button>
                        </div>

                        <div className="button-fixture">
                            <button
                                className={activeTab === "fixture" ? "active" : ""}
                                onClick={() => setActiveTab("fixture")}
                            >
                                Fikstür
                            </button>
                        </div>
                        <div className="button-results">
                            <button
                                className={activeTab === "results" ? "active" : ""}
                                onClick={() => setActiveTab("results")}
                            >
                                Sonuçlar
                            </button>
                        </div>
                        <div className="button-team">
                            <button
                                className={activeTab === "team" ? "active" : ""}
                                onClick={() => setActiveTab("team")}
                            >
                                Takım
                            </button>
                        </div>
                    </div>

                    <div className="tab-content">
                        {/* {activeTab === "overview" && (
                            <Overview team={team} matches={Matches} />
                        )} */}

                        {activeTab === "fixture" && (
                            <MatchList matches={Matches.filter(match => match.is_it_fixture === true)} team={team} scoreTime={false} />
                        )}

                        {activeTab === "team" && (
                            <PlayerInfromations players={Players} />
                        )}

                        {activeTab === "results" && (
                            <MatchList matches={Matches.filter(match => match.is_it_fixture === false)} team={team} scoreTime={true} />)}

                        {activeTab === "stats" && (
                            <div className="stats-content">
                                <div className="stats-summary">
                                    <div className="stat-card">
                                        <h3>Toplam Maçlar</h3>
                                        <p>{team.total_matches}</p>
                                    </div>
                                    <div className="stat-card">
                                        <h3>Kazanılan Maçlar</h3>
                                        <p>{team.team_wins}</p>
                                    </div>
                                    <div className="stat-card">
                                        <h3>Kaybedilen Maçlar</h3>
                                        <p>{team.team_losses}</p>
                                    </div>
                                    <div className="stat-card">
                                        <h3>Berabere Maçlar</h3>
                                        <p>{team.team_draws}</p>
                                    </div>
                                    <div className="stat-card">
                                        <h3>Toplam Atılan Gol</h3>
                                        <p>{team.goals_conceded}</p>
                                    </div>
                                    <div className="stat-card">
                                        <h3>Toplam Puan</h3>
                                        <p>{team.team_wins * 3 + team.team_draws}</p>
                                    </div>
                                </div>
                                <div className="detailed-stats">
                                    <div className="stat-item">
                                        <h4>Galibiyet Oranı</h4>
                                        <div className="stat-bar">
                                            <div className="progress" style={{ width: `${(team.team_wins / team.total_matches) * 100}%` }}></div>
                                        </div>
                                    </div>
                                    <div className="stat-item">
                                        <h4>Beraberlik Oranı</h4>
                                        <div className="stat-bar">
                                            <div className="progress" style={{ width: `${(team.team_draws / team.total_matches) * 100}%` }}></div>
                                        </div>
                                    </div>
                                    <div className="stat-item">
                                        <h4>Maç Başına Gol</h4>
                                        <div className="stat-bar">
                                            <div className="progress" style={{ width: `${(team.total_goals / team.total_matches) * 100}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
                            
            <br></br>
            <br></br>
            <Footer />
        </div>


    );
};
function formatTeamNameForURL(teamName) {
    return teamName
        .toLowerCase()
        .replace(/ç/g, "c")
        .replace(/ğ/g, "g")
        .replace(/ı/g, "i")
        .replace(/ö/g, "o")
        .replace(/ş/g, "s")
        .replace(/ü/g, "u")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
        .replace(/\s+/g, "-");
}

function PlayerInfromations({ players }) {
    return (
        <div className="team-player-list">
            {players.map((player, index) => (
                <div key={index} className="team-player-card">
                    <div className="team-player-stats">
                        <div className="team-stat-item">
                            <h4>Toplam Maçlar</h4>
                            <p>{player.total_matches}</p>
                        </div>
                        <div className="team-stat-item">
                            <h4>Değeri</h4>
                            <p>{player.value || 0}</p>
                        </div>
                        <div className="team-stat-item">
                            <h4>Goller</h4>
                            <p>{player.total_goals || 0}</p>
                        </div>
                        <div className="team-stat-item">
                            <h4>Zaferler</h4>
                            <p>{player.wins || 0}</p>
                        </div>
                    </div>
                    <div className="team-player-photo">
                        <img src={player.player_img} alt={player.name} />
                    </div>
                    <div className="team-player-info">
                        <h2>
                            {player.player_name}
                        </h2>
                        <a href={`/oyuncular/${player.id}`} className="team-view-profile">View Profile →</a>
                    </div>
                </div>
            ))}
        </div>
    );
}

const formatTime = (timeString) => {
    const time = timeString.slice(0, 5);
    return `${time}`;
};

const getMatchWinner = (matchDetails) => {
    if (matchDetails.first_team_score > matchDetails.second_team_score) {
        return matchDetails.first_team_name;
    } else if (matchDetails.first_team_score < matchDetails.second_team_score) {
        return matchDetails.second_team_name;
    } else {
        return "Berabere";
    }
};

const getPoint = (matchDetails, teamName) => {
    const result = getMatchWinner(matchDetails);

    if (result === teamName) {
        return 3;
    } else if (result === "Berabere") {
        return 1;
    } else {
        return 0; // 
    }
};
const formatDate = (isoDate) => {
    const months = [
        "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
        "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
    ];

    const date = new Date(isoDate); // Tarih objesi oluştur
    const day = date.getDate(); // Gün
    const month = months[date.getMonth()]; // Ay (yazıyla)
    const year = date.getFullYear(); // Yıl

    return `${day} ${month} ${year}`;
};

function MatchList({ matches, team, scoreTime }) {
    const groupMatchesByDate = (matches) => {
        return matches.reduce((acc, match) => {
            const matchDate = formatDate(match.date); // formatDate'i kullanarak tarih gruplandırma
            if (!acc[matchDate]) {
                acc[matchDate] = [];
            }
            acc[matchDate].push(match);
            return acc;
        }, {});
    };

    const formatDate = (isoDate) => {
        if (!isoDate) {
            return "";
        }
        const months = [
            "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
            "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
        ];

        const date = new Date(isoDate); // Tarih objesi oluştur
        const day = date.getDate(); // Gün
        const month = months[date.getMonth()]; // Ay (yazıyla)
        const year = date.getFullYear(); // Yıl

        return `${day} ${month} ${year}`;
    };

    const formatTime = (timeString) => {
        const time = timeString.slice(0, 5);
        return `${time}`;
    };

    const groupedMatches = groupMatchesByDate(matches);

    return (
        <div className="fixture-container">
            {Object.keys(groupedMatches) // Gruplandırılmış tarihleri sırala
                .reverse()
                .map((date) => (
                    <React.Fragment key={date}>
                        {/* Tarih Başlığı */}
                        <h2 className="fixture-date">{date}</h2>

                        {/* Maçlar */}
                        {groupedMatches[date]
                            .sort((a, b) => new Date(`1970-01-01T${a.time}`) - new Date(`1970-01-01T${b.time}`))
                            .map((match) => (
                                <div className="fixture-row" key={match.id}>
                                    {/* Birinci Takım */}
                                    <div className="team" style={{textAlign:'right'}}>
                                        <span>{match.first_team_name}</span>
                                        <img
                                            src={match.first_team.logo}
                                            alt={match.first_team_name}
                                            className="team-logo"
                                        />
                                    </div>

                                    {/* Maç Saati */}
                                    <div className="time">
                                        {scoreTime ? `${match.first_team_score}-${match.second_team_score}` : formatTime(match.time)}
                                    </div>

                                    {/* İkinci Takım */}
                                    <div className="team">
                                        <img
                                            src={match.second_team.logo}
                                            alt={match.second_team_name}
                                            className="team-logo"
                                        />
                                        <span>{match.second_team_name}</span>
                                    </div>

                                    {/* Ek Bilgiler */}
                                    <div className="stadium">
                                        <img
                                            src={field}
                                            alt={match.match_field}
                                            className="team-logo"
                                        />
                                        <span><a
                                            href={`https://www.google.com/maps?q=${encodeURIComponent("100. Yıl Bulvarı, 1245. Sk. No:9, 06170 Yenimahalle")}`}
                                            target="_blank" /* Yeni sekmede aç */
                                            rel="noopener noreferrer" /* Güvenlik için */
                                            className="stadium-link"
                                        >{match.match_field}</a></span>
                                    </div>
                                    <div className="actions">
                                        <button className="quick-view" onClick={() => window.location.href = `/maclar/${match.id}`}>
                                            {scoreTime ? "Maça Git" : "Quick View"}
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </React.Fragment>
                ))}
        </div>

    );
};

function Overview({ team, matches }) {
    return (
        <div className="overview-container">
            {/* Left Sidebar */}
            <aside className="sidebar2">
                <section className="sidebar-contain">
                    <h3>Takım Logosu:</h3>
                    <img src={team.logo} alt={team.team_name} className='team-logo-img'></img>
                </section>
                <section className="sidebar-contain">
                    <h3>Takım İsmi:</h3>
                    <p>{team.team_name}</p>
                </section>
                <section className="sidebar-contain">
                    <h3>Kuruluş Tarihi:</h3>
                    <p>{formatDate(team.attendence_date)}</p>
                </section>
                <section className="social-links">
                    <h3>ElitLig Channels:</h3>
                    <div className="social-icons">
                        <a href="#" className="social-link">
                            <i className="fab fa-facebook-f"></i> Facebook
                        </a>
                        <a href="#" className="social-link">
                            <i className="fab fa-twitter"></i> Twitter
                        </a>
                        <a href="#" className="social-link">
                            <i className="fab fa-instagram"></i> Instagram
                        </a>
                    </div>
                </section>
            </aside>

            {/* Main Content */}
            <main className="main-content">
                <section className="membership">
                    <div className="membership-info">
                        <h2>Newcastle Digital Membership</h2>
                        <p>Sign up to NUFC TV to watch the latest Newcastle videos...</p>
                        <button>More information</button>
                    </div>
                    <div className="membership-image">
                        <img src="membership-image.jpg" alt="Membership" />
                    </div>
                </section>

                <section className="latest-news">
                    <h3>Latest Club News</h3>
                    <div className="news-cards">
                        <div className="news-card">
                            <img src="news1.jpg" alt="News 1" />
                            <p>Newcastle United 0 West Ham United 2...</p>
                        </div>
                        <div className="news-card">
                            <img src="news2.jpg" alt="News 2" />
                            <p>Magpies maintain momentum...</p>
                        </div>
                        <div className="news-card">
                            <img src="news3.jpg" alt="News 3" />
                            <p>Nottingham Forest 1 Newcastle United 3...</p>
                        </div>
                    </div>
                </section>

                <section className="kits">
                    <h3>Kits</h3>
                    <div className="kit-cards">
                        <div className="kit-card">
                            <img src="home-kit.jpg" alt="Home Kit" />
                            <button>Buy Now</button>
                        </div>
                        <div className="kit-card">
                            <img src="away-kit.jpg" alt="Away Kit" />
                            <button>Buy Now</button>
                        </div>
                        <div className="kit-card">
                            <img src="third-kit.jpg" alt="Third Kit" />
                            <button>Buy Now</button>
                        </div>
                    </div>
                </section>

                <section className="videos">
                    <h3>Videos</h3>
                    <div className="video-cards">
                        <div className="video-card">
                            <img src="video1.jpg" alt="Video 1" />
                            <p>Newcastle 0-2 West Ham...</p>
                        </div>
                        <div className="video-card">
                            <img src="video2.jpg" alt="Video 2" />
                            <p>Best goals of last season...</p>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default TeamPage;
