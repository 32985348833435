import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Remove_Player.css";

const RemovePlayer = ({ closeModal }) => {
    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamPlayers, setTeamPlayers] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [filteredTeams, setFilteredTeams] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    useEffect(() => {
        // Tüm takımları yükle
        const fetchTeams = async () => {
            try {
                const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar");
                setTeams(response.data);
            } catch (error) {
                console.error("Error fetching teams:", error);
                setErrorMessage("Takımlar yüklenemedi.");
            }
        };
        fetchTeams();
    }, []);

    const handleTeamSelect = async (teamId) => {
        setSelectedTeam(teamId);
        setLoading(true);
        try {
            const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/${teamId}`);
            setTeamPlayers(response.data);
        } catch (error) {
            console.error("Error fetching team players:", error);
            setErrorMessage("Takım oyuncuları yüklenemedi.");
        } finally {
            setLoading(false);
        }
    };
    const handleSearch = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setSearchTerm(searchValue);
        const filtered = teams.filter((team) =>
            team.team_name.toLowerCase().includes(searchValue)
        );
        setFilteredTeams(filtered);
    };

    const handleRemovePlayer = async (playerId) => {
        const confirmRemove = window.confirm("Oyuncuyu takımdan çıkarmak istediğinize emin misiniz?");
        if (!confirmRemove) return;

        try {
            // Oyuncunun atanmış takım ID'si kontrol ediliyor
            const playerResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${playerId}`);
            const playerTeamId = playerResponse.data[0].team_id;

            if (playerTeamId === parseInt(selectedTeam)) {
                alert("Bu oyuncuyu atanmış takımından silemezsiniz!");
                return;
            }

            // PlayerTeams tablosundan kaydı sil
            await axios.delete(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/${playerId}/${selectedTeam}`);
            alert("Oyuncu başarıyla takımdan çıkarıldı.");
            handleTeamSelect(selectedTeam); // Oyuncu listesi güncelle
        } catch (error) {
            console.error("Error removing player from team:", error);
            alert("Oyuncu takımdan çıkarılamadı.");
        }
    }; 

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Takımdan Oyuncu Çıkar</h2>
                <span className="close-button" onClick={closeModal}>
                    ×
                </span>

                <div className="r-team-select">
                    <label>Takım Ara:</label>
                    <input
                        type="text"
                        placeholder="Takım adı ara..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="r-team-search-input"
                    />
                </div>

                <div className="r-team-list scrollable">
                    {filteredTeams.map((team) => (
                        <button
                            key={team.id}
                            className={`r-team-item ${selectedTeam === team.id ? "selected" : ""}`}
                            onClick={() => handleTeamSelect(team.id)}
                        >
                            {team.team_name}
                        </button>
                    ))}
                </div>

                {loading && <p>Yükleniyor...</p>}

                {teamPlayers.length > 0 && (
                    <ul className="player-list">
                        {teamPlayers.map((player) => (
                            <li key={player.player_id} className="player-item">
                                {player.player_name}{"   "}
                                <button
                                    className="remove-button"
                                    onClick={() => handleRemovePlayer(player.player_id)}
                                >
                                    Çıkar
                                </button>
                            </li>
                        ))}
                    </ul>
                )}

                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
        </div>
    );
};

export default RemovePlayer;
