import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import './LeagueStandings.css';

const LeagueStandings = () => {
    const [matches, setMatches] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [teams, setTeams] = useState([]);
    const [selectedLeague, setSelectedLeague] = useState(null);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [filteredMatches, setFilteredMatches] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const matchesResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar");
                setMatches(matchesResponse.data);

                const seasonsResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar");
                setSeasons(seasonsResponse.data);

                const leaguesResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler");
                setLeagues(leaguesResponse.data);

                const teamsResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar");
                setTeams(teamsResponse.data);
            } catch (error) {
                console.error("Hata:", error);
            }
        };

        fetchData();
    }, []);

    // Lig veya sezon değiştiğinde filtreleme işlemini tetikleyin
    useEffect(() => {
        if (selectedLeague && selectedSeason) {
            const filtered = matches.filter(
                (match) =>
                    match.league_name === selectedLeague &&
                    match.match_season === selectedSeason &&
                    match.is_it_fixture === false
            );
            setFilteredMatches(filtered);
        } else {
            setFilteredMatches([]); // Seçim olmadığında boş liste
        }
    }, [selectedLeague, selectedSeason, matches]);

    const handleLeagueChange = (leagueName) => {
        setSelectedLeague(leagueName);
        setSelectedSeason(null); // Lig değiştiğinde sezonu sıfırla
    };

    const handleSeasonChange = (seasonId) => {
        setSelectedSeason(seasonId);
    };

    const calculateTeamStats = (teamName, matches) => {
        // Maçları takım adıyla filtrele
        const teamMatches = matches.filter(
            (match) => match.first_team_name === teamName || match.second_team_name === teamName
        );

        // Maçları tarihlerine göre sırala
        teamMatches.sort((a, b) => new Date(a.date) - new Date(b.date));

        const stats = {
            wins: 0,
            draws: 0,
            losses: 0,
            goalsFor: 0,
            goalsAgainst: 0,
            lastFiveMatches: [], // Son 5 maç bilgisi için yeni alan
        };

        teamMatches.forEach((match) => {
            const isHome = match.first_team_name === teamName;
            const teamScore = isHome ? match.first_team_score : match.second_team_score;
            const opponentScore = isHome ? match.second_team_score : match.first_team_score;

            stats.goalsFor += teamScore;
            stats.goalsAgainst += opponentScore;

            if (teamScore > opponentScore) stats.wins++;
            else if (teamScore === opponentScore) stats.draws++;
            else stats.losses++;

            // Maç detaylarını ekle
            stats.lastFiveMatches.push({
                matchId: match.id, // Maç ID'si
                result:
                    teamScore > opponentScore
                        ? 'win'
                        : teamScore === opponentScore
                            ? 'draw'
                            : 'loss',
            });
        });

        // Sadece son beş maçı al
        stats.lastFiveMatches = stats.lastFiveMatches.slice(-5);

        return stats;
    };
    function formatTeamNameForURL(teamName) {
        return teamName
            .toLowerCase()
            .replace(/ç/g, "c")
            .replace(/ğ/g, "g")
            .replace(/ı/g, "i")
            .replace(/ö/g, "o")
            .replace(/ş/g, "s")
            .replace(/ü/g, "u")
            .replace(/[^a-z0-9 ]/g, "")
            .trim()
            .replace(/\s+/g, "-");
    }

    const teamStats = teams.map((team) => ({
        ...team,
        stats: calculateTeamStats(team.team_name, filteredMatches),
    }));

    return (
        <div >
            <Header />
            <header class="header-banner">
                <h1 class="banner-title">Takım Listesi</h1>
                <div class="zigzag">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#4facfe" fill-opacity="1" d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,96C672,128,768,192,864,213.3C960,235,1056,213,1152,213.3C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                    </svg>
                </div>
            </header>
            <div className='centered-container-2'>
                <select onChange={(e) => handleLeagueChange(e.target.value)}>
                    <option value="">Lig Seç</option>
                    {leagues.map((league) => (
                        <option key={league.id} value={league.league_name}>
                            {league.league_name}
                        </option>
                    ))}
                </select>

                {selectedLeague && (
                    <select onChange={(e) => handleSeasonChange(e.target.value)}>
                        <option value="">Sezon Seç</option>
                        {seasons
                            .filter((season) => season.league_name === selectedLeague)
                            .map((season) => (
                                <option key={season.id} value={season.season_name}>
                                    {season.season_name}
                                </option>
                            ))}
                    </select>
                )}
            </div>
            <div class="table-container">
                {selectedLeague && selectedSeason && (
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Position</th>
                                    <th>Club</th>
                                    <th>Played</th>
                                    <th>Won</th>
                                    <th>Drawn</th>
                                    <th>Lost</th>
                                    <th>GF</th>
                                    <th>GA</th>
                                    <th>GD</th>
                                    <th>Points</th>
                                    <th>Form</th>
                                    <th>More</th>
                                </tr>
                            </thead>
                            <tbody>
                                {teamStats
                                    .sort((a, b) => {
                                        const pointsA = a.stats.wins * 3 + a.stats.draws;
                                        const pointsB = b.stats.wins * 3 + b.stats.draws;
                                        return pointsB - pointsA;
                                    })
                                    .map((team, index) => (
                                        <tr key={team.id}>
                                            <td>{console.log(team.team_name, team.stats)}{index + 1}</td>
                                            <td style={{display:'flex', textAlign:'left', alignItems:'center', fontWeight:'bolder'}}>
                                                <img src={team.logo} alt={team.team_name} className='little-logo-style'></img>
                                                <span>{team.team_name}</span>
                                            </td>
                                            <td>{team.stats.wins + team.stats.draws + team.stats.losses}</td>
                                            <td>{team.stats.wins}</td>
                                            <td>{team.stats.draws}</td>
                                            <td>{team.stats.losses}</td>
                                            <td>{team.stats.goalsFor}</td>
                                            <td>{team.stats.goalsAgainst}</td>
                                            <td>{team.stats.goalsFor - team.stats.goalsAgainst}</td>
                                            <td style={{ fontWeight: 'bolder', fontSize: '17px' }}>{team.stats.wins * 3 + team.stats.draws}</td>
                                            <td>
                                                {team.stats.lastFiveMatches && team.stats.lastFiveMatches.length > 0 ? (
                                                    team.stats.lastFiveMatches.map((match, i) => (
                                                        <span
                                                            key={i}
                                                            class={`form ${match.result}`}
                                                        >
                                                            {console.log(match)}{match.result.slice(0, 1).toUpperCase()}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <span style={{ color: "gray" }}>-</span>
                                                )}
                                            </td>
                                            <td onClick={() => window.location.href = `/takimlar/${formatTeamNameForURL(team.team_name)}`}>➡</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>


            <Footer />
        </div>
    );
};

export default LeagueStandings;
