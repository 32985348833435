import './Home.css';
import logo from '../assets/elitLig_logo.png';
import defaultImage from '../assets/field.jpg';
import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from "axios";
import Header from './Header';
import Footer from './Footer';
import { useEffect, useState } from 'react';


function Home() {
    const [matches, setMatches] = useState([]);
    const [teams, setTeams] = useState([]);
    const [players, setPlayers] = useState([]);
    const [groupedMatches, setGroupedMatches] = useState([]);

    useEffect(() => {
        const fetchLastFiveMatches = async () => {
            try {
                const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/last-five");
                setMatches(response.data);
            } catch (error) {
                console.error("Hata:", error);
            }
        };

        const fetchTeams = async () => {
            try {
                const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/");
                setTeams(response.data);
            } catch (error) {
                console.error("Hata takımlarda:", error);
            }
        };

        const fetchPlayers = async () => {
            try {
                const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/");
                setPlayers(response.data);
            } catch (error) {
                console.error("Hata:", error);
            }
        };
        const fetchGroupedMatches = async () => {
            try {
                const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar");
                setGroupedMatches(response.data);
            } catch (error) {
                console.error("Hata:", error);
            }
        };

        fetchGroupedMatches();
        fetchPlayers();
        fetchLastFiveMatches();
        fetchTeams();
    }, []);

    const sortedMatches = groupedMatches
        .filter(match => match.is_it_fixture === true) 
        .sort((a, b) => new Date(a.date) - new Date(b.date)) // Tarihe göre sırala
        .reverse();



    const highestValuePlayer = players.length > 0
        ? players.reduce((maxPlayer, currentPlayer) => (
            currentPlayer.value > maxPlayer.value ? currentPlayer : maxPlayer
        ), players[0])
        : null;

    const highestGoalPlayer = players.length > 0
        ? players.reduce((maxPlayer, currentPlayer) => (
            currentPlayer.total_goals > maxPlayer.total_goals ? currentPlayer : maxPlayer
        ), players[0])
        : null;


    // Group matches by date
    const groupedMatchesAll = sortedMatches.reduce((groups, match) => {
        const date = match.date;
        if (!groups[date]) groups[date] = [];
        groups[date].push(match);
        return groups;
    }, {});

    const formatDateTime = (timeString) => {
        const time = timeString.slice(0, 5);
        return `${time}`;
    };


    return (
        <div style={{ backgroundColor: '#ecebeb' }}>
            <Header />
            <div className="little-match-container">
                {matches
                    .filter((match) => !match.is_it_fixture)
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .map((match, index) => (
                        <div className="box" key={index} style={{ textWrap: 'nowrap' }} onClick={() => window.location.href = `/maclar/${match.id}`}>
                            <h3>{match.first_team_name.substring(0, 7)}</h3>
                            <img
                                src={teams.find(team => team.team_name === match.first_team_name)?.logo || logo}
                                alt={match.first_team_name || 'Unknown Team'}
                                className='team-logo2'
                            />
                            <div className="score">{match.first_team_score}-{match.second_team_score}</div>
                            <img
                                src={teams.find(team => team.team_name === match.second_team_name)?.logo || logo}
                                alt={match.second_team_name || 'Unknown Team'}
                                className='team-logo2'
                            />                            <h3>{match.second_team_name.substring(0, 7)}</h3>
                        </div>
                    ))}
                <div className='box' onClick={() => window.location.href = `/sonuclar`}><h3>TÜM MAÇLARI GÖRÜNTÜLE</h3></div>
            </div>

            <div className='main-container'>
                <div className="all-table-container">
                    <div className="match-table">
                        <table>
                            <thead className="thead-style">
                                <tr>
                                    <th colSpan="3">FİKSTÜR</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(groupedMatchesAll)
                                    .slice(0, 4)
                                    .map((date) => (
                                        <React.Fragment key={date}>
                                            <div className='date'>
                                                {new Date(date).toLocaleDateString('tr-TR', {
                                                    day: 'numeric',
                                                    month: 'long',
                                                    year: 'numeric',
                                                    weekday: 'long',
                                                })}
                                            </div>
                                            {groupedMatchesAll[date]
                                                .map((match) => (
                                                    <React.Fragment key={match.id}>
                                                        <div className="match-info2">
                                                            <span>{match.league_name || 'Bilinmeyen Lig'} | {match.match_season || 'Bilinmeyen Sezon'}</span>
                                                        </div>
                        
                                                        <div className='match-row' onClick={() => window.location.href = `/maclar/${match.id}`}>
                                                            <span>{match.first_team_name.substring(0, 7)}</span>
                                                            <img
                                                                src={teams.find(team => team.team_name === match.first_team_name)?.logo || logo}
                                                                alt={match.first_team_name || 'Unknown Team'}
                                                                className='team-logo2'
                                                            />
                                                            <div className="score">{formatDateTime(match.time)}</div>
                                                            <img
                                                                src={teams.find(team => team.team_name === match.second_team_name)?.logo || logo}
                                                                alt={match.second_team_name || 'Unknown Team'}
                                                                className='team-logo2'
                                                            />
                                                            <span>{match.second_team_name.substring(0, 7)}</span>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                        </React.Fragment>
                                    ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="table middle-table">
                        <tbody className='tbody-style'>
                            <MatchCarousel matches={matches.filter(match => match.is_it_fixture === false)} />
                        </tbody>
                    </div>

                    <div className="match-table">
                        <table>
                            <thead className="thead-style">
                                <tr>
                                    <th colSpan="3">ELİT LİG</th>
                                </tr>
                            </thead>
                            <thead className='team-row'>
                                <span className='team-index'>Sıra</span>
                                <span className="team-info2">
                                    Takım Adı
                                </span>
                                <div className="team-stats">
                                    <span>G</span>
                                    <span>M</span>
                                    <span>P</span>
                                </div>
                            </thead>

                            <tbody>
                                        {teams.sort((a, b) => {
    const aPoints = a.team_wins * 3 + (a.team_draws || 0); // Beraberlikler için fallback
    const bPoints = b.team_wins * 3 + (b.team_draws || 0);
    return bPoints - aPoints; // Büyükten küçüğe sıralama
})
.slice(0, 20)
.map((team, index) => (
    <React.Fragment key={team.id || index}>
        <div className='team-row' onClick={() => window.location.href = `/takimlar/${formatTeamNameForURL(team.team_name)}`}>
            <span className='team-index'>{index + 1}</span>
            <span className="team-info2">
                <img
                    src={team?.logo || logo}
                    alt={team.team_name.substring(0, 12) || 'Unknown Team'}
                    className='team-logo2'
                />
                {team.team_name}
            </span>
            <div className="team-stats">
                <span>{team.team_wins}</span>
                <span>{team.team_losses}</span>
                <span>{team.team_wins*3 + team.team_draws}</span>
            </div>
        </div>
    </React.Fragment>
))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="bottom-tables">
                    <PlayerInfromations players={players} goalValue={true} />
                    <PlayerInfromations players={players} goalValue={false} />
                </div>

            </div>

            <Footer />
        </div>
    )
}

function formatTeamNameForURL(teamName) {
    return teamName
        .toLowerCase()
        .replace(/ç/g, "c")
        .replace(/ğ/g, "g")
        .replace(/ı/g, "i")
        .replace(/ö/g, "o")
        .replace(/ş/g, "s")
        .replace(/ü/g, "u")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
        .replace(/\s+/g, "-");
}
function PlayerInfromations({ players, goalValue }) {
    const player = players.sort((a, b) => b.total_goals - a.total_goals)[0];
    return (
        <div class="player-card-container">
            {player ? (
                <div className="team-player-card">
                    <div className="team-player-stats">
                        <div className="team-stat-item">
                            <h4>Toplam Maçlar</h4>
                            <p>{player.total_matches}</p>
                        </div>
                        <div className="team-stat-item">
                            {goalValue ? (
                                <>
                                    <h4>Toplam Gol</h4>
                                    <p>{player.total_goals || 0}</p>
                                </>
                            ) : (
                                <>
                                    <h4>Değeri</h4>
                                    <p>{player.value || 0}</p>
                                </>
                            )}
                        </div>
                        <div className="team-stat-item">
                            <h4>Puan</h4>
                            <p>{player.total_points || 0}</p>
                        </div>
                        <div className="team-stat-item">
                            <h4>Zaferler</h4>
                            <p>{player.wins || 0}</p>
                        </div>
                    </div>

                    <div className="team-player-photo">
                        <img src={player.player_img} alt={player.name} />
                    </div>

                    <div className="team-player-info">
                        <h2>
                            {player.player_name}
                        </h2>
                        <a href={`/oyuncular/${player.id}`} className="team-view-profile">View Profile →</a>
                    </div>
                </div>
            ) : (
                <p>Veri yükleniyor...</p>
            )}
            <div class="row-table">
                <table>
                    <thead>
                        <tr style={{ backgroundColor: '#37043c', color: 'white' }}>
                            <th className="poz" style={{ color: 'white' }}>Sıra</th>
                            <th style={{ textAlign: 'left', color: 'white' }}>Oyuncu</th>
                            <th style={{ color: 'white' }}>{goalValue
                                ? `Gol`
                                : `Değeri`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {players
                            .slice(0, 10)
                            .sort((a, b) => (b.value) - (a.value))
                            .map((player, index) => (
                                <tr>
                                    <td className="poz">
                                        <a href={`/oyuncular/${player.id}`} className="table-link">{index + 1}</a>
                                    </td>
                                    <td style={{ textAlign: 'left' }}>
                                        <a href={`/oyuncular/${player.id}`} className="table-link">
                                            <img src={player.player_img} alt={player.player_name} className='team-logo2'/>
                                            {player.player_name}
                                        </a>
                                    </td>
                                    <td>
                                        {goalValue
                                            ? <a href={`/oyuncular/${player.id}`} className="table-link">{player.total_goals}</a>
                                            : <a href={`/oyuncular/${player.id}`} className="table-link">{player.value}</a>}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>

    );
}

const MatchCarousel = ({ matches }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextMatch = () => {
        setCurrentIndex((currentIndex + 1) % matches.length);
    };

    const prevMatch = () => {
        setCurrentIndex((currentIndex - 1 + matches.length) % matches.length);
    };

    const currentMatch = matches.length > 0 ? matches[currentIndex] : null;

    return (
        <div>
            {currentMatch ? (
                <div className="image-container">
                    <img src={currentMatch.match_picture || defaultImage} alt={currentMatch.match_title} className="image" />
                    <div className="button-container">
                        <button className="overlay-button left" onClick={prevMatch}>◄</button>
                        <button className="overlay-button right" onClick={nextMatch}>►</button>
                    </div>
                    <a href={`/maclar/${currentMatch.id}`}>
                        <button className="sideline-button">{currentMatch.match_title}</button>
                    </a>
                </div>
            ) : (
                <p>Yükleniyor...</p>
            )}
        </div>
    );
};

export default Home
