// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ user, roleRequired, children }) => {
  // If no user or role doesn't match, redirect to the homepage

  const allowedRoles = Array.isArray(roleRequired) ? roleRequired : [roleRequired];

  // Eğer kullanıcı yoksa veya rolü belirtilen rollerden biri değilse ana sayfaya yönlendir
  if (!user || !allowedRoles.includes(user.role)) {
    return <Navigate to="/" replace />;
  }

  // If everything is fine, render the children components
  return children;
};

export default ProtectedRoute;
