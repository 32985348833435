import React, { useState, useEffect } from "react";
import axios from "axios";
import './Editor.css';

const Editor = () => {
    const [stats, setStats] = useState({
        leagues: 0,
        seasons: 0,
        teams: 0,
        players: 0,
        matches: 0
    });

    useEffect(() => {
        fetchStats();
    }, []);

    const fetchStats = async () => {
        try {
            const leaguesResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler");
            const seasonsResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar");
            const teamsResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar");
            const playersResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular");
            const matchesResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar");

            setStats({
                leagues: leaguesResponse.data.length,
                seasons: seasonsResponse.data.length,
                teams: teamsResponse.data.length,
                players: playersResponse.data.length,
                matches: matchesResponse.data.length
            });
        } catch (error) {
            console.error("Error fetching stats:", error);
        }
    };

    return (
        <div className="editor-content">
            {/* İstatistik Barı */}
            <div className="editor-stats-bar">
                <div className="editor-stat-item">
                    <span className="editor-stat-title">Lig Sayısı</span>
                    <span className="editor-stat-value">{stats.leagues}</span>
                </div>
                <div className="editor-stat-item">
                    <span className="editor-stat-title">Sezon Sayısı</span>
                    <span className="editor-stat-value">{stats.seasons}</span>
                </div>
                <div className="editor-stat-item">
                    <span className="editor-stat-title">Takım Sayısı</span>
                    <span className="editor-stat-value">{stats.teams}</span>
                </div>
                <div className="editor-stat-item">
                    <span className="editor-stat-title">Oyuncu Sayısı</span>
                    <span className="editor-stat-value">{stats.players}</span>
                </div>
                <div className="editor-stat-item">
                    <span className="editor-stat-title">Maç Sayısı</span>
                    <span className="editor-stat-value">{stats.matches}</span>
                </div>
            </div>

        
        </div>
        
    );
};

export default Editor;
