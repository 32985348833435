import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Add_Team_Player.css';

const AddTeamPlayer = ({ closeModal, refreshTeams }) => {
    const [teams, setTeams] = useState([]);
    const [filteredTeams, setFilteredTeams] = useState([]);
    const [allPlayers, setAllPlayers] = useState([]);
    const [filteredPlayers, setFilteredPlayers] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null); // Takım nesnesi (ID ve Name)
    const [playerInputs, setPlayerInputs] = useState([{ playerId: '', playerName: '' }]);
    const [teamDropdownVisible, setTeamDropdownVisible] = useState(false);
    const [playerDropdownVisible, setPlayerDropdownVisible] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchTeams();
        fetchPlayers();
    }, []);

    const fetchTeams = async () => {
        try {
            const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar");
            setTeams(response.data);
            setFilteredTeams(response.data);
        } catch (error) {
            console.error("Error fetching teams:", error);
        }
    };

    const fetchPlayers = async () => {
        try {
            const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular");
            setAllPlayers(response.data);
            setFilteredPlayers(response.data);
        } catch (error) {
            console.error("Error fetching players:", error);
        }
    };

    const handleTeamSearch = (value) => {
        setFilteredTeams(
            teams.filter((team) =>
                team.team_name.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handlePlayerSearch = (index, value) => {
        const updatedInputs = [...playerInputs];
        updatedInputs[index].playerName = value;
        setPlayerInputs(updatedInputs);

        setFilteredPlayers(
            allPlayers.filter((player) =>
                player.player_name.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handlePlayerSelect = (index, player) => {
        const updatedInputs = [...playerInputs];
        updatedInputs[index] = { playerId: player.player_id, playerName: player.player_name };
        setPlayerInputs(updatedInputs);

        // Listeyi kapat
        const updatedDropdowns = [...playerDropdownVisible];
        updatedDropdowns[index] = false;
        setPlayerDropdownVisible(updatedDropdowns);
    };

    const handleTeamSelect = (team) => {
        setSelectedTeam({ team_id: team.team_id, team_name: team.team_name }); // Takım ID ve adı saklanıyor
        setTeamDropdownVisible(false);
    };

    const handleAddPlayerRow = () => {
        setPlayerInputs([...playerInputs, { playerId: '', playerName: '' }]);
        setPlayerDropdownVisible([...playerDropdownVisible, false]);
    };

    const handleSavePlayers = async () => {
        if (!selectedTeam) {
            setErrorMessage("Lütfen bir takım seçin.");
            return;
        }
    
        try {
            for (const input of playerInputs) {
                // `player_name` ile `player_id`'yi eşle
                const player = allPlayers.find((p) => p.player_name === input.playerName);
    
                // `team_name` ile `team_id`'yi eşle
                const team = teams.find((t) => t.team_name === selectedTeam.team_name);
    
                if (player && team) {
                    // PlayerTeams tablosunda bu kaydın olup olmadığını kontrol et
                    const existingRecordResponse = await axios.get(
                        `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/check?player_id=${player.id}&team_id=${team.id}`
                    );
    
                    if (existingRecordResponse.data.exists) {
                        setErrorMessage(`Oyuncu zaten takımın kadrosunda:  ${player.player_name}`);
                        return;
                    }
    
                    // Eğer kayıt yoksa yeni oyuncu-takım ilişkisini ekle
                    await axios.post("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/add", {
                        player_id: player.id,
                        player_name: input.playerName,
                        team_id: team.id,
                        team_name: selectedTeam.team_name,
                    });
                } else {
                    setErrorMessage("Geçerli bir oyuncu veya takım bulunamadı.");
                    return;
                }
            }
    
            setSuccessMessage("Oyuncular başarıyla eklendi!");
            setErrorMessage('');
            refreshTeams();
    
        } catch (error) {
            console.error("Error adding players to team:", error);
            setErrorMessage("Bir hata oluştu. Lütfen tekrar deneyin.");
        }
    };
    
    

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={closeModal}>
                    &times;
                </button>
                <h2>Takıma Oyuncu Ekle</h2>

                <div>
                    <input
                        type="text"
                        placeholder="Takım Ara..."
                        onFocus={() => setTeamDropdownVisible(true)}
                        onChange={(e) => handleTeamSearch(e.target.value)}
                        className="custom-input"
                        value={selectedTeam?.team_name || ''} // Seçilen takım adını göster
                        
                    />
                    {teamDropdownVisible && (
                        <ul className="team-list">
                            {filteredTeams.map((team) => (
                                <li
                                    key={team.team_name}
                                    onClick={() => handleTeamSelect(team)} // Takım seçimi
                                    className={`team-item ${selectedTeam?.team_name === team.team_name ? 'selected' : ''}`}
                                >
                                    {team.team_name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className="player-input-container scrollable-container">
                    {playerInputs.map((input, index) => (
                        <div key={index} className="player-row">
                            <input
                                type="text"
                                placeholder="Oyuncu ara"
                                value={input.playerName}
                                onFocus={() => {
                                    const updatedDropdowns = [...playerDropdownVisible];
                                    updatedDropdowns[index] = true;
                                    setPlayerDropdownVisible(updatedDropdowns);
                                }}
                                onChange={(e) => handlePlayerSearch(index, e.target.value)}
                                className="custom-input"
                                
                            />
                            {playerDropdownVisible[index] && (
                                <ul className="player-dropdown">
                                    {filteredPlayers.map((player) => (
                                        <li
                                            key={player.id}
                                            onClick={() => handlePlayerSelect(index, player)}
                                            className="player-item"
                                        >
                                            {player.player_name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </div>

                <button onClick={handleAddPlayerRow} className="add-row-button">
                    + Oyuncu Ekle
                </button>

                <button onClick={handleSavePlayers} className="save-button">
                    Kaydet
                </button>

                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
            </div>
        </div>
    );
};

export default AddTeamPlayer;
