import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Editor_Teams.css';
import './Add_Teams.css';
import AddTeams from './Add_Teams';
import UpdateTeam from './Update_Team';
import AddTeamPlayer from './Add_Team_Player.js';
import RemovePlayer from './Remove_Player.js';  

const Editor_Teams = () => {
    const [teams, setTeams] = useState([]);
    const [filteredTeams, setFilteredTeams] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Her sayfada gösterilecek takım sayısı
    const [isAddPlayerModalOpen, setIsAddPlayerModalOpen] = useState(false); // Yeni popup kontrolü
    
    const [isRemovePlayerModalOpen, setIsRemovePlayerModalOpen] = useState(false);
    const baseUrl = "https://elit-lig-back-end-bc5730e96b98.herokuapp.com/";
    // Veritabanından takımları çekme
    useEffect(() => {
        fetchTeams();
    }, []);

    const fetchTeams = async () => {
        try {
            const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar");
            const sortedTeams = response.data.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB - dateA; // En son eklenen en üstte
            });
            setTeams(sortedTeams);
            setFilteredTeams(sortedTeams);
        } catch (error) {
            console.error("Error fetching teams:", error);
        }
    };

    // Takım arama fonksiyonu
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchQuery(value);
        const filtered = teams.filter(team =>
            team.team_name.toLowerCase().includes(value)
        );
        setFilteredTeams(filtered);
        setCurrentPage(1); // Arama yapıldığında sayfayı başa al
    };

    const getPaginationButtons = () => {
        const range = [];
        const maxButtons = 5; // Aynı anda gösterilecek maksimum buton sayısı
        const ellipsis = "...";

        if (totalPages <= maxButtons) {
            // Eğer toplam sayfa sayısı azsa tüm butonları göster
            for (let i = 1; i <= totalPages; i++) {
                range.push(i);
            }
        } else {
            // İlk sayfa, son sayfa ve aktif sayfaya göre aralıklı butonlar ekle
            if (currentPage <= 3) {
                range.push(1, 2, 3, ellipsis, totalPages);
            } else if (currentPage >= totalPages - 2) {
                range.push(1, ellipsis, totalPages - 2, totalPages - 1, totalPages);
            } else {
                range.push(1, ellipsis, currentPage - 1, currentPage, currentPage + 1, ellipsis, totalPages);
            }
        }
        return range;
    };


    // Sayfalandırma için takımları böl
    const totalPages = Math.ceil(filteredTeams.length / itemsPerPage);
    const indexOfLastTeam = currentPage * itemsPerPage;
    const indexOfFirstTeam = indexOfLastTeam - itemsPerPage;
    const currentTeams = filteredTeams.slice(indexOfFirstTeam, indexOfLastTeam);


    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Güncelleme modalını açma
    const openUpdateModal = (team) => {
        setSelectedTeam(team);
        setIsUpdateModalOpen(true);
    };

    const closeUpdateModal = () => {
        setIsUpdateModalOpen(false);
        setSelectedTeam(null);
    };
    const openAddPlayerModal = () => {
        setIsAddPlayerModalOpen(true);
    };

    const closeAddPlayerModal = () => {
        setIsAddPlayerModalOpen(false);
    };
    const openRemovePlayerModal = () => {
        setIsRemovePlayerModalOpen(true);
    };
    
    const closeRemovePlayerModal = () => {
        setIsRemovePlayerModalOpen(false);
    };

    return (
        <div className="teams-container">
            {/* Yeni Takım Ekle Butonu */}
            <button className="add-buttonn" onClick={handleOpenModal}>
                Yeni Takım Ekle
            </button>
            <button className="add-buttonn" onClick={openAddPlayerModal}>
                Takımlara Oyuncu Ekle
            </button>
            <button className="add-buttonn" onClick={openRemovePlayerModal}>
                Takımdan Oyuncu Çıkar
            </button>

            {/* Takım Arama Alanı */}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Takım Ara..."
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>

            {/* Takımlar Tablosu */}
            <div className="teams-table">
                <div className="table-header-team">
                    <span>Logo</span>
                    <span>Takımlar</span>
                    <span>Ligi</span>
                    <span>Sezonu</span>
                    <span className="empty-space"></span>
                </div>

                {currentTeams.map((team, index) => (
                    <div key={index} className="team-item">
                        <span>
                            {team.logo && (
                                <img
                                src={team.logo}
                                    alt={`${team.team_name} logo`}
                                    className="team-logo"
                                />
                            )}
                        </span>
                        <span>{team.team_name}</span>
                        <span>{team.current_league}</span>
                        <span>{team.current_season}</span>
                        <button className="update-button" onClick={() => openUpdateModal(team)}>
                            Güncelle
                        </button>
                    </div>
                ))}
            </div>

            {/* Sayfalandırma (Pagination) */}

            <div className="pagination">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    &lt; Önceki
                </button>
                {getPaginationButtons().map((page, index) =>
                    page === "..." ? (
                        <span key={index} className="ellipsis">
                            ...
                        </span>
                    ) : (
                        <button
                            key={page}
                            className={page === currentPage ? "active" : ""}
                            onClick={() => handlePageChange(page)}
                        >
                            {page}
                        </button>
                    )
                )}
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Sonraki &gt;
                </button>
            </div>


            {/* Modal */}
            {isModalOpen && (
                <AddTeams closeModal={handleCloseModal} refreshTeams={fetchTeams} />
            )}
            {isUpdateModalOpen && selectedTeam && (
                <UpdateTeam
                    selectedTeam={selectedTeam}
                    onClose={closeUpdateModal}
                    refreshTeams={fetchTeams}
                />
            )}
            {isAddPlayerModalOpen && (
                <AddTeamPlayer closeModal={closeAddPlayerModal} refreshTeams={fetchTeams} />
            )}
            {isRemovePlayerModalOpen && (
                <RemovePlayer closeModal={closeRemovePlayerModal} />
            )}



        </div>
    );
};

export default Editor_Teams;
