import Header from './Header';
import Footer from './Footer';
import './Player.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';



import { useParams } from 'react-router-dom';

const PlayerPage = () => {
    const [player, setPlayer] = useState([]);
    const [players, setPlayers] = useState([]);
    const [playerMatches, setPlayerMatches] = useState([]);
    const { playerID } = useParams();
    const [activeTab, setActiveTab] = useState("overview");

    useEffect(() => {
        const fetchPlayer = async () => {
            try {
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${playerID}`);
                const playerData = response.data[0];
                const teamPlayer = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${playerData.team_id}`);
                const teamData = teamPlayer.data;
                const combinedData = {
                    ...playerData, // Oyuncu bilgilerini kopyala
                    team: teamData, // Takım bilgilerini "team" anahtarı altında ekle
                };

                // Birleştirilmiş veriyi state'e kaydet
                setPlayer(combinedData);

            } catch (error) {
                console.error("Hata:", error);
            }
        };
        fetchPlayer();
        const fetchPlayers = async () => {
            try {
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular`);
                setPlayers(response.data);
            } catch (error) {
                console.error("Hata:", error);
            }
        };
        fetchPlayers();
        const fetchPlayerMatches = async () => {
            try {
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-istatistikleri/oyuncu/${playerID}`);
                const playerStatistics = response.data;

                const matchesPromises = playerStatistics.map(async (stat) => {
                    const matchResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${stat.match_id}`);
                    const teamPlayer = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${stat.team_id}`);
                    const teamOpponent = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${stat.opponent_team_id}`);
                    return {
                        ...stat,
                        matchDetails: matchResponse.data,
                        teamPlayer: teamPlayer.data,
                        teamOpponent: teamOpponent.data
                    };
                });

                const combinedData = await Promise.all(matchesPromises);
                const groupedByTeam = combinedData.reduce((acc, item) => {
                    const teamName = item.teamPlayer.team_name;
                    if (!acc[teamName]) {
                        acc[teamName] = [];
                    }
                    acc[teamName].push(item);
                    return acc;
                }, {});

                setPlayerMatches(groupedByTeam);
            } catch (error) {
                console.error("Hata:", error);
            }
        };
        fetchPlayerMatches();
    }, []);


    const sortedPlayers = [...players].sort((a, b) => b.total_points - a.total_points);
    const playerRank = sortedPlayers.findIndex(player => player.id === Number(playerID));

    const getMatchWinner = (matchDetails) => {
        if (matchDetails.first_team_score > matchDetails.second_team_score) {
            return matchDetails.first_team_name;
        } else if (matchDetails.first_team_score < matchDetails.second_team_score) {
            return matchDetails.second_team_name;
        } else {
            return "Berabere";
        }
    };
    const getResultSymbol = (matchDetails, teamName) => {
        const result = getMatchWinner(matchDetails);
        const score = `${matchDetails.first_team_score} - ${matchDetails.second_team_score}`;

        if (result === teamName) {
            return `W ${score}`; // Kazandı
        } else if (result === "Berabere") {
            return `D ${score}`; // Beraberlik
        } else {
            return `L ${score}`; // Kaybetti
        }
    };
    const formatDate = (isoDate) => {
        if (!isoDate) {
            return "";
        }
        const months = [
            "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
            "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
        ];

        const date = new Date(isoDate); // Tarih objesi oluştur
        const day = date.getDate(); // Gün
        const month = months[date.getMonth()]; // Ay (yazıyla)
        const year = date.getFullYear(); // Yıl

        return `${day} ${month} ${year}`;
    };
    return (
        <div>
            <Header />
            <div className="player-profile">
                <div className="profile-header">
                    <div className="player-info">
                        <img
                            src={player.player_img}
                            alt={player.player_name}
                            className="player-img"
                        />
                        <h1>{player.player_name}</h1>
                    </div>
                    <div className="player-number">
                        {playerRank}
                    </div>

                </div>

                <div className="player-information">
                    <div className="buttons">
                        <div className="button-overview">
                            <button
                                className={activeTab === "overview" ? "active" : ""}
                                onClick={() => setActiveTab("overview")}
                            >
                                Overview
                            </button>
                        </div>
                        <div className="button-stat">
                            <button
                                className={activeTab === "stats" ? "active" : ""}
                                onClick={() => setActiveTab("stats")}
                            >
                                Stats
                            </button>
                        </div>

                    </div>

                    <div className="tab-content">
                        {activeTab === "overview" && (
                            <div className="match-list">
                                <h3>Yapılan Maçlar</h3>
                                <div style={{ overflowX: "auto" }}> {/* Yatay kaydırma için ek div */}
                                    <table className="match-table2">
                                        <thead>
                                            <tr>
                                                <th style={{ borderTopLeftRadius: '10px' }}>Tarih</th>
                                                <th>Rakip Takım</th>
                                                <th>Sonuçlar</th>
                                                <th>Gol</th>
                                                <th style={{ borderTopRightRadius: '10px' }}>Puan</th>
                                            </tr>
                                        </thead>
                                        {Object.entries(playerMatches).map(([teamName, matches]) => (
                                            <tbody key={teamName}>
                                                <tr>
                                                    <td colSpan="5" style={{ textAlign: 'left', fontWeight: 'bold' }} onClick={() => window.location.href = `/takimlar/${formatTeamNameForURL(teamName)}`}>{teamName}</td>
                                                </tr>
                                                {matches.map((match, index) => (
                                                    <tr key={index} onClick={() => window.location.href = `/maclar/${match.match_id}`}>
                                                        <td>{formatDate(match.matchDetails.date)}</td>
                                                        <td style={{ textAlign: 'left' }}>
                                                            <img src={match.teamOpponent.logo} alt={match.teamOpponent.team_name} className="team-logo2" />
                                                            {match.teamOpponent.team_name}
                                                        </td>
                                                        <td>{getResultSymbol(match.matchDetails, teamName)}</td>
                                                        <td>{match.goals_minutes}</td>
                                                        <td>{match.points}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        )}

                        {activeTab === "stats" && (
                            <div className="stats-content">
                                <div className="stats-summary">
                                    <div className="stat-card">
                                        <h3>Değeri</h3>
                                        <p>{player.value}</p>
                                    </div>
                                    <div className="stat-card">
                                        <h3>Toplam Puan</h3>
                                        <p>{player.total_points}</p>
                                    </div>
                                    <div className="stat-card">
                                        <h3>Toplam Goller</h3>
                                        <p>{player.total_goals}</p>
                                    </div>
                                    <div className="stat-card">
                                        <h3>Galibiyet</h3>
                                        <p>{player.wins}</p>
                                    </div>
                                    <div className="stat-card">
                                        <h3>Mağlubiyet</h3>
                                        <p>{player.losses}</p>
                                    </div>
                                    <div className="stat-card">
                                        <h3>Beraberlik</h3>
                                        <p>{player.draws}</p>
                                    </div>

                                    <div className="stat-card">
                                        <h3>Sarı Kart</h3>
                                        <p>{player.total_yellow_cards}</p>
                                    </div>
                                    <div className="stat-card">
                                        <h3>Kırmızı Kart</h3>
                                        <p>{player.total_red_cards}</p>
                                    </div>

                                </div>
                                <div className="detailed-stats">
                                    <div className="stat-item">
                                        <h4>Galibiyet Oranı</h4>
                                        <div className="stat-bar">
                                            <div className="progress" style={{ width: `${(player.wins / player.total_matches) * 100}%` }}></div>
                                        </div>
                                    </div>
                                    <div className="stat-item">
                                        <h4>Beraberlik Oranı</h4>
                                        <div className="stat-bar">
                                            <div className="progress" style={{ width: `${(player.draws / player.total_matches) * 100}%` }}></div>
                                        </div>
                                    </div>
                                    <div className="stat-item">
                                        <h4>Maç Başına Gol</h4>
                                        <div className="stat-bar">
                                            <div className="progress" style={{ width: `${(player.total_goals / player.total_matches) * 100}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="player-information-card">
                    <ul className="player-info-list">
                        <li>
                            <h3>Ait Olduğu Takım</h3>
                            {player && player.team ? (
                                <>
                                    <img src={player.team.logo} alt={player.team.team_name} className="team-logo2" />
                                    <strong>{player.team.team_name}</strong>
                                </>
                            ) : (
                                <strong>Yükleniyor...</strong>
                            )}
                        </li>
                        <li>
                            <h3>Kişisel Bilgiler</h3>
                            <strong>{player.player_name}</strong>
                        </li>
                        <li>
                            <strong>{player.nationality}</strong>
                        </li>
                        <li>
                            <strong>{formatDate(player.birth_date)}</strong>
                        </li>
                        <li>
                            <strong>{formatDate(player.attendence_date)}</strong>
                        </li>
                        <li>
                            <h3>Oynadığı Sezon</h3>
                            <strong>{player.season}</strong>
                        </li>

                    </ul>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    );
};
function formatTeamNameForURL(teamName) {
    return teamName
        .toLowerCase()
        .replace(/ç/g, "c")
        .replace(/ğ/g, "g")
        .replace(/ı/g, "i")
        .replace(/ö/g, "o")
        .replace(/ş/g, "s")
        .replace(/ü/g, "u")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
        .replace(/\s+/g, "-");
}

export default PlayerPage;
