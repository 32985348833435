import axios from 'axios';

const api = axios.create({
  baseURL: 'https://elit-lig-back-end-bc5730e96b98.herokuapp.com/api',
  withCredentials: true, // Çerezleri gönder
});

// Interceptor: Yanıt hatalarını yönetir
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const currentPath = window.location.pathname; // Geçerli rota

      // Sadece /editor ile başlayan rotalar için kontrol yap
      if (currentPath.startsWith('/editor')) {
        alert('Oturum süreniz doldu. Lütfen tekrar giriş yapın.');
        window.location.href = '/'; // Giriş sayfasına yönlendirme yap
      }
    }
    return Promise.reject(error);
  }
);

export default api;
