import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Editor_Players.css'; // Bu satırı ekleyin
import './Add_Teams.css';
import AddPlayers from './Add_Player.js';
import UpdatePlayer from './Update_Player.js';

const Editor_Players = () => {
    const [playerData, setPlayerData] = useState({
        player_name: "",
        player_img: "",
        team_id: 0,
        birth_date: "",
        nationality: "",
        value: 0,
        season: "",
        total_matches: 0,
        total_goals: 0,
        total_points: 0,
        total_yellow_cards: 0,
        total_red_cards: 0,
        wins: 0,
        draws: 0,
        losses: 0,
        attendence_date: ""
    });

    const [players, setPlayers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [filteredPlayers, setFilteredPlayers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const itemsPerPage = 10;
    // Sayfalandırma için oyunculari böl
    const totalPages = Math.ceil(filteredPlayers.length / itemsPerPage);
    const indexOfLastPlayer = currentPage * itemsPerPage;
    const indexOfFirstPlayer = indexOfLastPlayer - itemsPerPage;
    const currentPlayers = filteredPlayers.slice(indexOfFirstPlayer, indexOfLastPlayer);
    const baseUrl = "https://elit-lig-back-end-bc5730e96b98.herokuapp.com/";

    // Oyuncuları ve takımları yükle
    const fetchPlayersAndTeams = async () => {
        try {
            const playersResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular");

            // Oyuncuları en son eklenenden en eskiye doğru sırala
            const sortedPlayers = playersResponse.data.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB - dateA; // En son eklenen en üstte
            });

            setPlayers(sortedPlayers);
            setFilteredPlayers(sortedPlayers);

            const teamsResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar");
            // Takımları alfabetik olarak sırala
            setTeams(teamsResponse.data);
        } catch (err) {
            console.error("Error fetching players or teams", err);
        }
    };

    // Component yüklendiğinde oyuncuları ve takımları getir
    useEffect(() => {
        fetchPlayersAndTeams();
    }, []);

    const getPaginationButtons = () => {
        const range = [];
        const maxButtons = 5; // Aynı anda gösterilecek maksimum buton sayısı
        const ellipsis = "...";

        if (totalPages <= maxButtons) {
            // Eğer toplam sayfa sayısı azsa tüm butonları göster
            for (let i = 1; i <= totalPages; i++) {
                range.push(i);
            }
        } else {
            // İlk sayfa, son sayfa ve aktif sayfaya göre aralıklı butonlar ekle
            if (currentPage <= 3) {
                range.push(1, 2, 3, ellipsis, totalPages);
            } else if (currentPage >= totalPages - 2) {
                range.push(1, ellipsis, totalPages - 2, totalPages - 1, totalPages);
            } else {
                range.push(1, ellipsis, currentPage - 1, currentPage, currentPage + 1, ellipsis, totalPages);
            }
        }
        return range;
    };

    // refreshPlayers fonksiyonu fetchPlayersAndTeams'i çağırır
    const refreshPlayers = () => {
        fetchPlayersAndTeams();
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchQuery(value);
        const filtered = players
            .filter(player => player.player_name.toLowerCase().includes(value));
        setFilteredPlayers(filtered);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    // Güncelleme modalını açma
    const openUpdateModal = (player) => {
        setSelectedPlayer(player);
        setIsUpdateModalOpen(true);
    };

    const closeUpdateModal = () => {
        setIsUpdateModalOpen(false);
        setSelectedPlayer(null);
        fetchPlayersAndTeams(); // Oyuncu listesini yenile
    };

    return (
        <div>
            <div className="teams-container">
                {/* Yeni Oyuncu Ekle Butonu */}
                <button className="add-buttonn" onClick={handleOpenModal} >
                    Yeni Oyuncu Ekle
                </button>

                {/* Oyuncu Arama Alanı */}
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Oyuncu Ara..."
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                </div>

                {/* Oyuncular Tablosu */}
                <div className="teams-table">
                    <div className="table-header-player">
                    <span>Oyuncu Foto</span>
                        <span>Oyuncu Adı</span>
                        <span>Takımı</span>
                    </div>

                    {currentPlayers.map((player, index) => (
                        <div key={index} className="team-item-player">
                            <span>
                            {player.player_img && (
                                <img
                                src={player.player_img}
                                    alt={`${player.player_name} logo`}
                                    className="player-logo"
                                />
                            )}
                        </span>
                            <span>{player.player_name}</span>
                            <span>{player.team_name}</span>
                            <button className="update-button" onClick={() => openUpdateModal(player)}>
                                Güncelle
                            </button>
                        </div>
                    ))}
                </div>
                {/* Sayfalandırma (Pagination) */}

                <div className="pagination">
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
                        &lt; Önceki
                    </button>
                    {getPaginationButtons().map((page, index) =>
                        page === "..." ? (
                            <span key={index} className="ellipsis">
                                ...
                            </span>
                        ) : (
                            <button
                                key={page}
                                className={page === currentPage ? "active" : ""}
                                onClick={() => handlePageChange(page)}
                            >
                                {page}
                            </button>
                        )
                    )}
                    <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Sonraki &gt;
                    </button>
                </div>
                {/* Modal */}
                {isModalOpen && (
                    <AddPlayers closeModal={handleCloseModal} refreshTeams={fetchPlayersAndTeams} />
                )}
                {isUpdateModalOpen && selectedPlayer && (
                    <UpdatePlayer
                        selectedPlayer={selectedPlayer}
                        onClose={closeUpdateModal}
                        refreshPlayers={refreshPlayers}
                    />
                )}

            </div>
        </div>
    );
};

export default Editor_Players;