import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import logo from '../assets/team_logo1.png';
import logo2 from '../assets/team_logo2.png';
import './Match2.css';
import defaultPlayerImage from '../assets/defaultPlayer.png';
import { useNavigate, useParams } from 'react-router-dom';

const MatchPage = () => {
    const [cards, setCards] = useState({
        red: {},
        yellow: {}
    });
    const [match, setMatch] = useState([]);
    const [mvp, setMvp] = useState([]);
    const [matches, setMatches] = useState([]);
    const [PlayerStatistics, setPlayerStatistics] = useState([]);
    const { matchID } = useParams();

    useEffect(() => {
        const fetchMatchAndTeams = async () => {
            try {
                // Maç bilgilerini al
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchID}`);
                const matchData = response.data;

                // Takım bilgilerini al
                const firstTeamResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-name/${formatTeamNameForURL(matchData.first_team_name)}`);
                const secondTeamResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/by-name/${formatTeamNameForURL(matchData.second_team_name)}`);

                // Match bilgilerini ve takımları birleştir
                const matchWithTeams = {
                    ...matchData,
                    first_team: firstTeamResponse.data,
                    second_team: secondTeamResponse.data
                };

                setMatch(matchWithTeams);
            } catch (error) {
                console.error("Hata:", error);
            }
        };

        fetchMatchAndTeams();


        const fetchMvp = async () => {
            try {
                const match2 = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchID}`);
                const mvpId = match2.data.match_mvp;

                if (!mvpId) {
                    console.error("MVP ID bulunamadı.");
                    return;
                }

                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${mvpId}`);
                setMvp(response.data[0]);
            } catch (error) {
                console.error("Hata:", error);
            }
        };
        fetchMvp();

        const fetchMatches = async () => {
            try {
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar`);
                setMatches(response.data);
            } catch (error) {
                console.error("Hata:", error);
            }
        };
        fetchMatches();

        const fetchPlayerStatistics = async () => {
            try {
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-istatistikleri/mac/${matchID}`);
                const statistics = response.data;
                const playerDetails = await Promise.all(
                    statistics.map(async (stat) => {
                        const playerResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${stat.player_id}`);

                        const teamResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${stat.team_id}`);

                        return {
                            ...stat,
                            player: playerResponse.data,
                            team: teamResponse.data
                        };
                    })
                );

                const cards = playerDetails.reduce((acc, stat) => {
                    const teamName = stat.team.team_name;
                    if (stat.red_card && stat.red_card > 0) {
                        acc.red[teamName] = (acc.red[teamName] || 0) + stat.red_card;
                    } else {
                        if (!acc.red[teamName]) {
                            acc.red[teamName] = 0;
                        }
                    }
                    if (stat.yellow_card && stat.yellow_card > 0) {
                        acc.yellow[teamName] = (acc.yellow[teamName] || 0) + stat.yellow_card;
                    } else {
                        if (!acc.yellow[teamName]) {
                            acc.yellow[teamName] = 0;
                        }
                    }
                    return acc;
                }, { red: {}, yellow: {} });


                setCards(cards);
                setPlayerStatistics(playerDetails);
            } catch (error) {
                console.error("Hata:", error);
            }
        };
        fetchPlayerStatistics();
    }, []);

    const formatTime = (timeString) => {
        const time = timeString.slice(0, 5);
        return `${time}`;
    };

    return (
        <div>
            <Header />
            <div className='big-container'>

                <div className="container">
                    <div className="right-tables">
                        <table className="info-table">
                            <tbody>
                                <tr>
                                    <td onClick={() => window.location.href = `/${match.league_name}-sonuclar`} style={{ textAlign: 'left' }}><b>Lig Tablosu</b></td>
                                </tr>
                                <tr>
                                    <td onClick={() => window.location.href = `/duyurular`} style={{ textAlign: 'left' }}><b>Duyurular</b></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="info-table">
                            <thead>
                                <tr>
                                    <th>{match?.league_name
                                        ? match.league_name.charAt(0).toUpperCase() + match.league_name.slice(1)
                                        : 'Veri yükleniyor...'} Diğer Maçlar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {matches
                                    .filter(currentMatch => currentMatch.league_name === match.league_name)
                                    .slice(0, 3)
                                    .map(currentMatch => (
                                        <tr key={currentMatch.id}>
                                            <td>
                                                <a href={`/maclar/${currentMatch.id}`}>
                                                    <b>{currentMatch.first_team_name.substring(0, 3)}</b>
                                                    <div className="score">{formatTime(currentMatch.time)}</div>
                                                    <b>{currentMatch.second_team_name.substring(0, 3)}</b>                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="outer-box">
                        <div className="side-rectangle left-rectangle">
                            {match.first_team && match.first_team.logo ? (
                                <img
                                    src={match.first_team.logo}
                                    alt={match.first_team_name || "Takım Adı"}
                                    className="team-logo team-logo-left"
                                />
                            ) : (
                                <p>Takım bilgileri yükleniyor...</p> // Alternatif olarak bir yükleme yazısı ya da boş bir div gösterebilirsiniz
                            )}                            <span onClick={() => window.location.href = `/takimlar/${formatTeamNameForURL(match.first_team_name)}`}>{match.first_team_name}</span>
                        </div>
                        <div className="inner-box">
                            {match.first_team_score} - {match.second_team_score}
                        </div>
                        <div className="side-rectangle right-rectangle" style={{ flexDirection: 'row-reverse' }}>
                            {match.second_team && match.second_team.logo ? (
                                <img
                                    src={match.second_team.logo}
                                    alt={match.second_team_name || "Takım Adı"}
                                    className="team-logo team-logo-right"
                                />
                            ) : (
                                <p>Takım bilgileri yükleniyor...</p> // Yükleme sırasında gösterilecek alternatif içerik
                            )}
                            <span onClick={() => window.location.href = `/takimlar/${formatTeamNameForURL(match.second_team_name)}`}>{match.second_team_name}</span>
                        </div>
                        <MatchLineDivider
                                positions={PlayerStatistics.filter(player => player.number_of_goals > 0)} // Filter uygulanıyor
                                team_name={match.first_team_name}
                                match={match}
                            /> 
                        <div className="goals left">
                            {PlayerStatistics
                                .filter(player => player.team.team_name === match.first_team_name && player.number_of_goals > 0)
                                .map((player, index) => (
                                    player.goals_minutes.length > 1 || player.goals_minutes !== "0" ? (
                                        <b key={index} onClick={() => window.location.href = `/oyuncular/${player.id}`}>
                                            {player.player[0].player_name} - {player.goals_minutes}'
                                        </b>
                                    ) : null
                                ))}
                        </div>
                        <div className="goals right">
                            {PlayerStatistics
                                .filter(player => player.team.team_name === match.second_team_name && player.number_of_goals > 0)
                                .map((player, index) => (
                                    player.goals_minutes.length > 1 || player.goals_minutes !== "0" ? (
                                        <b key={index} onClick={() => window.location.href = `/oyuncular/${player.id}`}>
                                            {player.player[0].player_name} - {player.goals_minutes}'
                                        </b>
                                    ) : null
                                ))}
                        </div>
                    </div>

                    <div className="player-card" onClick={() => window.location.href = `/oyuncular/${match.match_mvp}`}>
                        <div className="player-header">
                            {mvp ? ( // mvp kontrolü
                                <div className="team-player-card">
                                    <div className="team-player-stats">
                                        <div className="team-stat-item">
                                            <h4>Toplam Maçlar</h4>
                                            <p>{mvp.total_matches}</p>
                                        </div>
                                        <div className="team-stat-item">
                                            <h4>Değeri</h4>

                                            <p>{mvp.value || 0}</p>
                                        </div>
                                        <div className="team-stat-item">
                                            <h4>Goller</h4>
                                            <p>{mvp.total_goals || 0}</p>
                                        </div>
                                        <div className="team-stat-item">
                                            <h4>Zaferler</h4>
                                            <p>{mvp.wins || 0}</p>
                                        </div>
                                    </div>
                                    <div className="team-player-photo">
                                        <img src={mvp.player_img} alt={mvp.player_name} />
                                    </div>
                                    <div className="team-player-info">
                                        <h2>
                                            {mvp.player_name}
                                        </h2>
                                        <a href={`/oyuncular/${mvp.id}`} className="team-view-profile">View Profile →</a>
                                    </div>
                                </div>
                            ) : (
                                <p>Yükleniyor...</p>
                            )}
                        </div>

                    </div>

                    <div className="stats-box">
                        <div className="team-stats">
                            <div className="team">
                                <h3>{match.first_team_name}</h3>
                                <img
        src={match?.first_team?.logo || logo} // Eğer logo yoksa varsayılan logo kullanılır
        alt={match?.first_team_name || "Takım Adı"}
        className="team-logo-stat"
    />
                                <div className="stat">
                                    <span className="label">Gol</span>
                                    <div className="bar">
                                        <div
                                            className="fill"
                                            style={{
                                                width: (match.first_team_score + match.second_team_score) === 0
                                                    ? "0%"
                                                    : `${(match.first_team_score / (match.first_team_score + match.second_team_score)) * 100}%`
                                            }}
                                        ></div>                                    </div>
                                </div>
                                <div className="stat">
                                    <span className="label">Kırmızı Kart</span>
                                    <div className="bar">
                                        <div className="fill"
                                            style={{
                                                width: (cards.red[match.first_team_name] + cards.red[match.second_team_name]) === 0
                                                    ? "0%"
                                                    : `${(cards.red[match.first_team_name] / (cards.red[match.first_team_name] + cards.red[match.second_team_name])) * 100}%`
                                            }}></div>
                                    </div>
                                </div>
                                <div className="stat">
                                    <span className="label">Sarı Kart</span>
                                    <div className="bar">
                                        <div className="fill"
                                            style={{
                                                width: (cards.yellow[match.first_team_name] + cards.yellow[match.second_team_name]) === 0
                                                    ? "0%"
                                                    : `${(cards.yellow[match.first_team_name] / (cards.yellow[match.first_team_name] + cards.yellow[match.second_team_name])) * 100}%`
                                            }}></div>
                                    </div>
                                </div>
                            </div>

                            <div className="match-info">
                                <div className="label">{match.match_season}. SEZON</div>
                                <div className="label">Maç Saati</div>
                                <div className="played">{match && match.time ? formatTime(match.time) : "Yükleniyor..."}</div>
                                <div className="label">
                                    {match.first_team_score > match.second_team_score
                                        ? `${match.first_team_name} Kazandı`
                                        : match.first_team_score < match.second_team_score
                                            ? `${match.second_team_name} Kazandı`
                                            : "Maç Berabere"}
                                </div>
                            </div>

                            <div className="team">
                                <h3>{match.second_team_name}</h3>
                                <img
        src={match?.second_team?.logo || logo} // Eğer logo yoksa varsayılan logo kullanılır
        alt={match?.second_team_name || "Takım Adı"}
        className="team-logo-stat"
    />
                                <div className="stat">
                                    <span className="label">Gol</span>
                                    <div className="bar">
                                        <div
                                            className="fill"
                                            style={{
                                                width: (match.first_team_score + match.second_team_score) === 0
                                                    ? "0%"
                                                    : `${(match.second_team_score / (match.first_team_score + match.second_team_score)) * 100}%`
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <div className="stat">
                                    <span className="label">Kırmızı Kart</span>
                                    <div className="bar">
                                        <div className="fill"
                                            style={{
                                                width: (cards.red[match.first_team_name] + cards.red[match.second_team_name]) === 0
                                                    ? "0%"
                                                    : `${(cards.red[match.second_team_name] / (cards.red[match.first_team_name] + cards.red[match.second_team_name])) * 100}%`
                                            }}></div>
                                    </div>
                                </div>
                                <div className="stat">
                                    <span className="label">Sarı Kart</span>
                                    <div className="bar">
                                        <div className="fill"
                                            style={{
                                                width: (cards.yellow[match.first_team_name] + cards.yellow[match.second_team_name]) === 0
                                                    ? "0%"
                                                    : `${(cards.yellow[match.second_team_name] / (cards.yellow[match.first_team_name] + cards.yellow[match.second_team_name])) * 100}%`
                                            }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="stat">
                            <span className="label">Topla Oynama Yüzdesi</span>
                            <div className="bar" style={{ display: 'flex' }}>
                                <div
                                    className="fill"
                                    style={{
                                        width: `${match.first_team_percentage}%`,
                                        backgroundColor: 'green'
                                    }}
                                ></div>
                                <div
                                    className="fill"
                                    style={{
                                        width: `${match.second_team_percentage}%`,
                                        backgroundColor: 'red',
                                        transform: 'scaleX(-1)'
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>
            {/* <Footer /> */}
        </div>

    );
};

function MatchLineDivider({ positions, team_name, match }) {
    const totalParts = 50;
    const [tooltip, setTooltip] = useState({ visible: false, content: '', position: 0 });

    return (
        <div className="bottom-line">
            {match.first_team && match.first_team.logo ? (
                <img
                    src={match.first_team.logo}
                    alt={match.first_team_name || "Takım Adı"}
                    className="little-logo"
                />
            ) : (
                <div className="placeholder-logo">Yükleniyor...</div>
            )}            {match.second_team && match.second_team.logo ? (
                <img
                    src={match.second_team.logo}
                    alt={match.second_team_name || "Takım Adı"}
                    className="little-logo"
                />
            ) : (
                <p>Takım bilgileri yükleniyor...</p> // Yükleme sırasında gösterilecek alternatif içerik
            )}
            {positions
                .filter(player => player.total_goals !== 0)
                .map((player) =>
                    String(player.goals_minutes).split(" ").filter(position => position !== "0").map((position, index) => {
                        const positionStyle = (position / totalParts) * 100;
                        return (
                            <div
                                key={index}
                                className="position-container"
                                style={{ left: `${positionStyle}%` }}
                                onMouseEnter={() =>
                                    setTooltip({
                                        visible: true,
                                        content: player.player[0].player_name,
                                        playerId: player.player[0].id,
                                        position: positionStyle
                                    })
                                }
                                onMouseLeave={() => setTooltip({ visible: false })}
                            >
                                {player.team.team_name == team_name ? (
                                    <>
                                        <div className="football-icon"></div>
                                        <div className="position-number">{position}</div>
                                    </>
                                ) : (
                                    <>
                                        <div className="position-number" style={{ marginTop: '30px' }}>{position}</div>
                                        <div className="football-icon"></div>
                                    </>
                                )}
                            </div>
                        );
                    })
                )}

            {tooltip.visible && (
                <div
                    className="tooltip"
                    style={{ left: `${tooltip.position}%` }}
                    onClick={() => window.location.href = `/oyuncular/${tooltip.playerId}`}
                >
                    {tooltip.content}
                </div>
            )}
        </div>
    );
}

function formatTeamNameForURL(teamName) {
    return teamName
        .toLowerCase()
        .replace(/ç/g, "c")
        .replace(/ğ/g, "g")
        .replace(/ı/g, "i")
        .replace(/ö/g, "o")
        .replace(/ş/g, "s")
        .replace(/ü/g, "u")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
        .replace(/\s+/g, "-");
}



export default MatchPage;
