import React, { useState, useEffect, useContext } from 'react';
import './Header.css';
import logo from '../assets/elitlig(2).png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { UserContext } from '../App';
import axios from 'axios';

const Header = () => {
    const [leagues, setLeagues] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);



    useEffect(() => {
        const fetchLeagues = async () => {
            try {
                const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler");
                setLeagues(response.data);
            } catch (error) {
                console.error("Hata:", error);
            }
        };
        fetchLeagues();
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const toggleDropdown = (league) => {
        setShowDropdown((prev) => ({
            ...prev,
            [league]: !prev[league]
        }));
    };

    const [showDropdown, setShowDropdown] = useState({
        otherLeagues: false,
    });
    const [hoveredLink, setHoveredLink] = useState(null);

    const handleMouseEnter = (link) => {
        setShowDropdown((prev) => ({ ...prev, [link]: true }));
        setHoveredLink(link);
    };

    const handleMouseLeave = (link) => {
        setShowDropdown((prev) => ({ ...prev, [link]: false }));
        setHoveredLink(null);
    };

    function formatTeamNameForURL(teamName) {
    return teamName
        .toLowerCase()
        .replace(/ç/g, "c")
        .replace(/ğ/g, "g")
        .replace(/ı/g, "i")
        .replace(/ö/g, "o")
        .replace(/ş/g, "s")
        .replace(/ü/g, "u")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
        .replace(/\s+/g, "-");
}

    const { user } = useContext(UserContext);
    return (
        <div>
            <div className="top-bar">
                <span><span style={{ fontFamily: '"Source Sans 3", sans-serif', fontWeight: 'bolder', color: '#37043c' }}>HEMEN MAÇ AL:</span> 0507 169 0888</span>
            </div>
            {/*header burada başlıyor */}
            <header className="header">
                <nav className="navbar">
                    {/* Logo */}
                    <div className="header-container logo-container">
                        <a href="/" className="nav-link">
                            <img src={logo} alt="Logo" />
                        </a>
                    </div>
                    <button className="hamburger" onClick={toggleSidebar}>
                        ☰
                    </button>

                    {/* Menüler */}
                    <div className="header-links">
                        <a href="/" className="nav-link">ANASAYFA</a>

                        {/* Freelig */}
                        <div
                            className="dropdown-container"
                            onMouseEnter={() => handleMouseEnter('freelig_')}
                            onMouseLeave={() => handleMouseLeave('freelig_')}
                        >
                            <a href="/" className={`nav-link ${hoveredLink === 'freelig_' ? 'hover' : ''}`}>
                                FREELİG
                            </a>
                            {showDropdown.freelig_ && (
                                <div className="dropdown">
                                    <a href="/freelig/sonuclar" className="dropdown-link">SONUÇLAR & FİKSTÜR</a>
                                    <a href="/freelig/puan-durumu" className="dropdown-link">PUAN DURUMU</a>
                                    <a href="/oyuncu-listesi" className="dropdown-link">OYUNCULAR</a>
                                </div>
                            )}
                        </div>

                        {/* Fikstürlü Lig */}
                        <div
                            className="dropdown-container"
                            onMouseEnter={() => handleMouseEnter('fiksturlu')}
                            onMouseLeave={() => handleMouseLeave('fiksturlu')}
                        >
                            <a href="/" className={`nav-link ${hoveredLink === 'fiksturlu' ? 'hover' : ''}`}>
                                FİKSTÜRLÜ LİG
                            </a>
                            {showDropdown.fiksturlu && (
                                <div className="dropdown">
                                    <a href="/fiksturlu/sonuclar" className="dropdown-link">SONUÇLAR & FİKSTÜR</a>
                                    <a href="/fiksturlu/puan-durumu" className="dropdown-link">PUAN DURUMU</a>
                                </div>
                            )}
                        </div>

                        <div
                            className="dropdown-container"
                            onMouseEnter={() => handleMouseEnter('otherLeagues')}
                            onMouseLeave={() => handleMouseLeave('otherLeagues')}
                        >
                            <a href="/" className={`nav-link ${hoveredLink === 'otherLeagues' ? 'hover' : ''}`}>
                                DİĞER LİGLER
                            </a>
                            {showDropdown.otherLeagues && (
                                <div className="dropdown">
                                    {leagues.map((league, index) => (
                                        <div
                                            key={index}
                                            className="dropdown-item"
                                            onMouseEnter={() => handleMouseEnter(league.league_name)}
                                            onMouseLeave={() => handleMouseLeave(league.league_name)}
                                        >
                                            <a
                                                href={`/`}
                                                className="dropdown-link"
                                            >
                                                {league.league_name}
                                            </a>
                                            {showDropdown[league.league_name] && (
                                                <div
                                                    className="dropdown dropdown-right"
                                                    style={{ top: `${index * 30}px` }}
                                                >
                                                    <a href={`/${league.league_name}/sonuclar`} className="dropdown-link">
                                                        SONUÇLAR & FİKSTÜR
                                                    </a>
                                                    <a href={`/${league.league_name}/puan-durumu`} className="dropdown-link">
                                                        PUAN DURUMU
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>


                        <a href="/iletisim" className="nav-link">İLETİŞİM</a>

                        <div
                            className="dropdown-container"
                            onMouseEnter={() => handleMouseEnter('bilgi')}
                            onMouseLeave={() => handleMouseLeave('bilgi')}
                        >
                            <a href="/" className={`nav-link ${hoveredLink === 'bilgi' ? 'hover' : ''}`}>
                                BİLGİ & DUYURU
                            </a>
                            {showDropdown.bilgi && (
                                <div className="dropdown">
                                    <a href="/cezalar" className="dropdown-link">CEZA TABLOSU</a>
                                    <a href="/haberler-ve-duyurular" className="dropdown-link">HABERLER</a>
                                    <a href="/kurallar" className="dropdown-link">KURALLAR</a>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="header-container">
                        {/* Kullanıcı giriş yapmamışsa "GİRİŞ YAP" butonunu göster */}
                        {!user && (
                            <a href="/giris" className="white-box">
                                GİRİŞ YAP
                            </a>
                        )}
                    </div>
                    <div className="header-container">
                        {/* Kullanıcı admin ya da editörse "EDİTÖR" bağlantısını göster */}
                        {user && (user.role === "admin" || user.role === "editor") && (
                            <a
                                className="white-box"
                                onMouseEnter={() => handleMouseEnter("editor")}
                                onMouseLeave={handleMouseLeave}
                                href="/editor"
                            >
                                EDİTÖR
                            </a>
                        )}
                    </div>
                </nav>
                <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                    <button className="close-btn" onClick={toggleSidebar}>✖</button>

                    <img src={logo} alt="Logo" style={{width:'80px'}}/>
                    <br/>

                    <div className="sidebar-item">
                        <a href="/" className="sidebar-link">ANASAYFA</a>
                    </div>

                    {leagues.map((league) => (
                        <div className="sidebar-item" key={league.league_name}>
                            <button
                                className="dropdown-btn"
                                onClick={() => toggleDropdown(league.league_name)}
                            >
                                {league.league_name}
                            </button>
                            {showDropdown[league.league_name] && (
                                <ul className="dropdown-list">
                                    <li>
                                        <a href={`/${formatTeamNameForURL(league.league_name)}/sonuclar`} className="sidebar-link">
                                            Sonuçlar
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`/${formatTeamNameForURL(league.league_name)}/puan-durumu`} className="sidebar-link">
                                            Puan Durumu
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </div>
                    ))}

                    <div className='sidebar-item'>
                        {user && (user.role === 'admin' || user.role === 'editor') && (
                            <a className="sidebar-link"
                                onMouseEnter={() => handleMouseEnter('editor')}
                                onMouseLeave={handleMouseLeave}
                                href="/editor">EDİTÖR</a>
                        )}
                    </div>
                    <div className='sidebar-item'>
                        <a className="sidebar-link"
                            onMouseEnter={() => handleMouseEnter('giris')}
                            onMouseLeave={handleMouseLeave}
                            href="/giris">GİRİŞ YAP</a>
                    </div>

                    <div className="sidebar-item">
                        <a href="/iletisim" className="sidebar-link">İLETİŞİM</a>
                    </div>
                </div>
            </header>


            <header className="sub-header">
                <nav className="navbar">
                    <div className='header-container'><a href='puan-durumu' className='nav-link' style={{ color: '#37043c' }}>Puan Tablosu</a></div>
                    <div className='header-container'><a href='/fikstur' className='nav-link' style={{ color: '#37043c' }}>Fikstür</a></div>
                    <div className='header-container'><a href='sonuclar' className='nav-link' style={{ color: '#37043c' }}>Sonuçlar</a></div>
                    <div className='header-container'><a href='takim-listesi' className='nav-link' style={{ color: '#37043c' }}>Takımlar</a></div>
                    <div className='header-container'><a href='en-degerliler-gol-kralligi' className='nav-link' style={{ color: '#37043c' }}>Oyuncular</a></div>
                    <div className='header-container'><a href='transferler' className='nav-link' style={{ color: '#37043c' }}>Transferler</a></div>
                    <div className='header-container'><a href='cezalar' className='nav-link' style={{ color: '#37043c' }}>Cezalar</a></div>
                    <div className='header-container'><a href='haberler-ve-duyurular' className='nav-link' style={{ color: '#37043c' }}>Duyurular</a></div>
                    <div className='header-container'><a href='odul-kosesi' className='nav-link' style={{ color: '#37043c' }}>Ödül Köşesi</a></div>
                </nav>
            </header>
        </div >
    );
};

export default Header;
