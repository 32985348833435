import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Editor_Seasons.css';

const AddSeasons = () => {
    const [seasons, setSeasons] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [newSeason, setNewSeason] = useState('');
    const [selectedLeague, setSelectedLeague] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Mevcut sezonları ve ligleri getir
    useEffect(() => {
        fetchSeasons();
        fetchLeagues();
    }, []);

    const fetchSeasons = async () => {
        try {
            const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar");
            setSeasons(response.data);
        } catch (error) {
            console.error("Error fetching seasons:", error);
        }
    };

    const fetchLeagues = async () => {
        try {
            const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler");
            setLeagues(response.data);
        } catch (error) {
            console.error("Error fetching leagues:", error);
        }
    };

    const handleAddSeason = async () => {
        if (!newSeason.trim() || !selectedLeague.trim()) {
            setErrorMessage("Lütfen tüm alanları doldurun.");
            setSuccessMessage('');
            return;
        }

        try {
            const currentYear = new Date().getFullYear().toString();

            // Mevcut sezon kontrolü
            const existingSeason = seasons.find(
                (season) =>
                    season.season_name.toLowerCase() === newSeason.toLowerCase() &&
                    season.league_name.toLowerCase() === selectedLeague.toLowerCase()
            );

            if (existingSeason) {
                setErrorMessage("Bu ligde bu isimde bir sezon zaten var.");
                setSuccessMessage('');
                return;
            }

            // Yeni sezonu ekle
            const response = await axios.post("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar", {
                season_name: newSeason.trim(),
                league_name: selectedLeague.trim(),
                season_year: currentYear,
            });

            if (response.status === 201) {
                setSuccessMessage("Sezon başarıyla eklendi!");
                setErrorMessage('');
            } else {
                setErrorMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
            }

            // Yeni sezonu ekledikten sonra listeyi güncelle
            await fetchSeasons();

            setNewSeason('');
            setSelectedLeague('');
        } catch (error) {
            console.error("Error adding season:", error);
            setErrorMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
            setSuccessMessage('');
        }
    };

    const handleDeleteSeason = async (seasonId) => {
        const confirmDelete = window.confirm("Sezonu silmek istediğinize emin misiniz?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar/${seasonId}`);
            setSeasons(seasons.filter((season) => season.id !== seasonId));
        } catch (error) {
            console.error('Error deleting season:', error);
            setErrorMessage("Sezon silinirken bir hata oluştu.");
        }
    };

    return (
        <div className="seasons-container">
            {/* Mevcut Sezonlar Tablosu */}
            <div className="existing-seasons">
                <div className="seasons-table">
                    <div className="table-header-season">
                        <span>Sezon</span>
                        <span>Ligi</span>
                        <span></span>
                    </div>
                    {seasons.map((season) => (
                        <div key={season.id} className="season-item">
                            <span>{season.season_name}</span>
                            <span>{season.league_name}</span>
                            
                                <button
                                className="delete-button"
                                onClick={() => handleDeleteSeason(season.id)}
                            >
                                Sil
                            </button>

    
                            
                        </div>
                    ))}
                </div>
            </div>

            {/* Yeni Sezon Ekle Bölümü */}
            <div className="add-season-section">
                <h2 className="section-title">Yeni Sezon Ekle</h2>
                <select
                    value={selectedLeague}
                    onChange={(e) => setSelectedLeague(e.target.value)}
                    className="league-select"
                >
                    <option value="">Lig Seçin</option>
                    {leagues.map((league) => (
                        <option key={league.id} value={league.league_name}>
                            {league.league_name}
                        </option>
                    ))}
                </select>
                <input
                    type="text"
                    value={newSeason}
                    onChange={(e) => setNewSeason(e.target.value)}
                    placeholder="Sezon adı girin..."
                    className="season-input"
                />
                <button onClick={handleAddSeason} className="add-button">
                    Oluştur
                </button>

                {/* Hata ve başarı mesajları */}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
            </div>
        </div>
    );
};

export default AddSeasons;
