import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Editor_Matches.css';

const Editor_Matches = () => {
    const [matches, setMatches] = useState([]);
    const [filteredMatches, setFilteredMatches] = useState([]);
    const [currentMatches, setCurrentMatches] = useState([]);

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOrder, setSortOrder] = useState('');
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [selectedLeague, setSelectedLeague] = useState('');
    const [selectedSeason, setSelectedSeason] = useState('');
    const [showNoStats, setShowNoStats] = useState(false);

    const [leagues, setLeagues] = useState([]); // Ligler
    const [seasons, setSeasons] = useState([]); // Sezonlar

    const itemsPerPage = 5;
    const navigate = useNavigate();
    const totalPages = Math.ceil(filteredMatches.length / itemsPerPage);

    // const baseUrl = "https://elit-lig-back-end-bc5730e96b98.herokuapp.com/";


    useEffect(() => {
    }, [filteredMatches]);
    
    useEffect(() => {
    }, [currentMatches]);
   
    useEffect(() => {
        const indexOfLastMatch = currentPage * itemsPerPage;
        const indexOfFirstMatch = indexOfLastMatch - itemsPerPage;
        setCurrentMatches(filteredMatches.slice(indexOfFirstMatch, indexOfLastMatch));
    }, [filteredMatches, currentPage]);
    
   

    useEffect(() => {
        fetchMatches();
        fetchFilters();
    }, []);

    useEffect(() => {
        sortMatches(sortOrder); // Sıralamayı sıralama durumu değiştiğinde güncelle
    }, [sortOrder]);

    const fetchFilters = async () => {
        try {
            const [leaguesResponse, seasonsResponse] = await Promise.all([
                axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler"),
                axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar")
            ]);

            setLeagues(leaguesResponse.data);
            setSeasons(seasonsResponse.data);
        } catch (error) {
            console.error("Filtre verileri alınamadı:", error);
        }
    };

    const fetchMatches = async () => {
        try {
            const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar");
            const formattedMatches = response.data.map(match => ({
                ...match,
                formattedDate: new Date(match.date).toLocaleDateString('tr-TR'),
                formattedTime: new Date(`1970-01-01T${match.time}`).toLocaleTimeString('tr-TR', {
                    hour: '2-digit',
                    minute: '2-digit',
                }),

            }));

            const sortedMatches = formattedMatches.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB - dateA;
            });

            setMatches(sortedMatches);
            setFilteredMatches(sortedMatches);
        } catch (error) {
            console.error("Error fetching matches:", error);
        }
    };

    const sortMatches = (order) => {
        if (order === '') {
            const sortedMatches = [...matches].sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB - dateA;
            });

            setFilteredMatches(sortedMatches);
            return;
        }

        const sorted = [...filteredMatches].sort((a, b) => {
            const dateTimeA = new Date(a.date);
            const dateTimeB = new Date(b.date);

            if (order === 'newest') {
                return dateTimeB - dateTimeA;
            }
            return dateTimeA - dateTimeB;
        });


        setFilteredMatches(sorted);
        return;
    };

    const getPaginationButtons = () => {
        const range = [];
        const maxButtons = 5; // Aynı anda gösterilecek maksimum buton sayısı
        const ellipsis = "...";

        if (totalPages <= maxButtons) {
            // Eğer toplam sayfa sayısı azsa tüm butonları göster
            for (let i = 1; i <= totalPages; i++) {
                range.push(i);
            }
        } else {
            // İlk sayfa, son sayfa ve aktif sayfaya göre aralıklı butonlar ekle
            if (currentPage <= 3) {
                range.push(1, 2, 3, ellipsis, totalPages);
            } else if (currentPage >= totalPages - 2) {
                range.push(1, ellipsis, totalPages - 2, totalPages - 1, totalPages);
            } else {
                range.push(1, ellipsis, currentPage - 1, currentPage, currentPage + 1, ellipsis, totalPages);
            }
        }
        return range;
    };

    const handleSortToggle = (order) => {
        if (sortOrder === order) {
            setSortOrder('');
            // sortMatches('');
        } else {
            setSortOrder(order);
            // sortMatches(order);
        }
        setCurrentPage(1);
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchQuery(value);
        const filtered = matches.filter(match =>
            match.first_team_name.toLowerCase().includes(value) ||
            match.second_team_name.toLowerCase().includes(value)
        );
        setFilteredMatches(filtered);
        setCurrentPage(1);
    };

    const handleFilter = () => {
        
        let filtered = matches;

        if (selectedLeague) {
            filtered = filtered.filter(match => match.league_name === selectedLeague);
        }


        if (selectedSeason) {
            filtered = filtered.filter(match => match.match_season === selectedSeason);
        }

        if (showNoStats) {
            filtered = filtered.filter(match => match.is_it_fixture === true);
        }


        setFilteredMatches(filtered);
        setCurrentPage(1); // Sayfayı başa al
        setIsFilterModalOpen(false);
    };

    const clearFilters = () => {
        setSelectedLeague('');
        setSelectedSeason('');
        setShowNoStats(false);
        setFilteredMatches(matches);
    };



    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleAddMatch = () => {
        navigate('/editor/mac-ekle');
    };

    const handleUpdateMatch = (matchId) => {
        navigate(`/editor/mac-guncelle/${matchId}`);
    };

    const handleEnterStats = (matchId) => {
        navigate(`/editor/ist-gir/${matchId}`);
    };



    return (
        <div className="matches-container">
            <button className="add-button-mac" onClick={handleAddMatch}>
                Yeni Maç Ekle
            </button>

            <div className="search-bar-mac">
                <input
                    type="text"
                    placeholder="Maç Ara..."
                    value={searchQuery}
                    onChange={handleSearch}
                />
                <div className="sort-toggle">
                    <button
                        className={sortOrder === 'newest' ? 'active' : ''}
                        onClick={() => handleSortToggle('newest')}
                    >
                        Önce En Yeni
                    </button>
                    <button
                        className={sortOrder === 'oldest' ? 'active' : ''}
                        onClick={() => handleSortToggle('oldest')}
                    >
                        Önce En Eski
                    </button>
                    <button className="filter-button" onClick={() => setIsFilterModalOpen(true)}>
                        Filtrele
                    </button>
                    <button className="clear-filter-button" onClick={clearFilters}>
                        Filtreleri Temizle
                    </button>
                </div>

            </div>

            {isFilterModalOpen && (
                <div className="filter-modal">
                    <div className="filter-modal-content">
                        <button
                            className="close-button"
                            onClick={() => setIsFilterModalOpen(false)}
                        >
                            ×
                        </button>
                        <h3>Filtreleme Seçenekleri</h3>
                        <label>
                            Lig Seç:
                            <select
                                value={selectedLeague}
                                onChange={(e) => setSelectedLeague(e.target.value)}
                            >
                                <option value="">Tüm Ligler</option>
                                {leagues.map((league) => (
                                    <option key={league.id} value={league.name}>
                                        {league.league_name}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label>
                            Sezon Seç:
                            <select
                                value={selectedSeason}
                                onChange={(e) => setSelectedSeason(e.target.value)}
                            >
                                <option value="">Tüm Sezonlar</option>
                                {seasons.map((season) => (
                                    <option key={season.id} value={season.name}>
                                        {season.season_name}{" "}{season.league_name}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={showNoStats}
                                onChange={(e) => setShowNoStats(e.target.checked)}
                            />
                            İstatistikleri Girilmemiş
                        </label>
                        <div className="modal-actions">
                            <button onClick={handleFilter}>Filtrele</button>

                        </div>
                    </div>
                </div>
            )}

            <div className="matches-table">
                <div className="table-header-mac">
                    <span>Takım</span>
                    <span>Skoru</span>
                    <span>Takım</span>
                    <span>Skoru</span>
                    <span>Gün</span>
                    <span>Saat</span> 
                    <span>Lig</span>
                    <span>İşlem</span>
                </div>

                {currentMatches.map((match, index) => (

                   <div key={index} className="match-item-main">
                        <div key={index} className="match-item">
                            <span>{match.first_team_name}</span>
                            <span>{match.first_team_score}</span>
                            <span>{match.second_team_name}</span>
                            <span>{match.second_team_score}</span>
                            <span>{match.formattedDate}</span>
                            <span>{match.formattedTime}</span>
                            <span>{match.league_name}</span>
                            {match.is_it_fixture ? (
                                <button
                                    className="enter-stats-button"
                                    onClick={() => handleEnterStats(match.id)}
                                >
                                    İst Gir
                                </button>
                            ) : (
                                <button
                                    className="update-button-mac"
                                    onClick={() => handleUpdateMatch(match.id)}
                                >
                                    Güncelle
                                </button>
                            )}
                        </div>

                        <div key={index} className="match-item-mobile">
                            <div className="match-pair">
                                <span >{match.first_team_name}</span>
                                <span >{match.first_team_score}</span>
                            </div>
                            <div className="match-pair">
                                <span >{match.second_team_name}</span>
                                <span >{match.second_team_score}</span>
                            </div>
                            <div className="match-pair">
                                <span >{match.formattedDate}</span>
                                <span >{match.formattedTime}</span>
                            </div>
                            <div className="match-pair">
                                <span >{match.league_name}</span>

                                <span >
                                    <div className="match-button">

                                        {match.is_it_fixture ? (
                                            <button
                                                className="enter-stats-button"
                                                onClick={() => handleEnterStats(match.id)}
                                            >
                                                İst Gir
                                            </button>
                                        ) : (
                                            <button
                                                className="update-button-mac"
                                                onClick={() => handleUpdateMatch(match.id)}
                                            >
                                                Güncelle
                                            </button>
                                        )}

                                    </div>
                                </span >
                            </div>

                        </div>
                    </div>
                ))}
            </div>

            <div className="pagination">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    &lt; Önceki
                </button>
                {getPaginationButtons().map((page, index) =>
                    page === "..." ? (
                        <span key={index} className="ellipsis">
                            ...
                        </span>
                    ) : (
                        <button
                            key={page}
                            className={page === currentPage ? "active" : ""}
                            onClick={() => handlePageChange(page)}
                        >
                            {page}
                        </button>
                    )
                )}
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Sonraki &gt;
                </button>
            </div>
        </div>
    );
};

export default Editor_Matches;
