import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import image from '../assets/field.jpg';
import Pagination from './Button';

const PlayerListDouble = () => {
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [selectedTable, setSelectedTable] = useState("value");
    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const response = await axios.get('https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular');
                setPlayers(response.data);
            } catch (err) {
                setError('Error fetching player data');
            } finally {
                setLoading(false);
            }
        };

        fetchPlayers();
    }, []);

    const playersPerPage = 25;

    const [currentPage, setCurrentPage] = useState(1);
    const lastIndex = currentPage * playersPerPage;
    const firstIndex = lastIndex - playersPerPage;

    // Seçime göre oyuncuları sıralıyoruz
    const sortedPlayers = players.sort((a, b) => {
        if (selectedTable === "value") return b.value - a.value; // En değerli oyuncular
        if (selectedTable === "goals") return b.total_goals - a.total_goals; // Gol krallığı
        return 0;
    });

    const currentPlayers = sortedPlayers.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(players.length / playersPerPage);

    return (
        <div>
            <Header />
            <header class="header-banner">
                <h1 class="banner-title">Oyuncu Listesi</h1>
                <div class="zigzag">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#4facfe" fill-opacity="1" d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,96C672,128,768,192,864,213.3C960,235,1056,213,1152,213.3C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                    </svg>
                </div>
            </header>
            <br></br>

            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <select
                    value={selectedTable}
                    onChange={(e) => setSelectedTable(e.target.value)}
                    style={{
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                    }}
                >
                    <option value="value">En Değerliler</option>
                    <option value="goals">Gol Krallığı</option>
                </select>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', flexDirection:'column', padding: '20px' }}>
                <table className='match-table2'>
                    <caption
                        style={{
                            ...textStyleBold,
                            fontSize: '20px',
                            padding: '20px',
                            textAlign: 'left',
                            borderBottom: '2px solid #ccc',
                        }}
                    >
                        {selectedTable === "value" ? "EN DEĞERLİLER" : "GOL KRALLIĞI"}
                    </caption>

                    <thead>
                        <tr style={{ textAlign: 'center', borderBottom: '2px solid #ccc' }}>
                            <th style={{ ...textStyleBold, padding: '10px', width: '5%' }}>SIRA</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '20%' }}>OYUNCU</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '20%' }}>TAKIM</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '5%' }}>OM</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '6%' }}>
                                {selectedTable === "value" ? "M.B. PUAN" : "M.B. GOL"}
                            </th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '6%' }}>
                                {selectedTable === "value" ? "DEĞERİ" : "GOL"}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPlayers.map((player, index) => (
                            <tr
                                key={index}
                                style={{ textAlign: 'center', borderBottom: '1px solid #ddd' }}
                            >
                                <td
                                    style={{ ...textStyleBold, padding: '10px', width: '5%' }}
                                >
                                    {(currentPage - 1) * playersPerPage + index + 1}
                                </td>
                                <td
                                    style={{
                                        ...textStyleBold,
                                        padding: '10px',
                                        width: '20%',
                                        textAlign: 'left',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <a
                                        href={`/oyuncular/${player.id}`}
                                        style={{
                                            whiteSpace: 'nowrap',
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        <img
                                            src={player.player_img}
                                            alt={player.player_name}
                                            style={{
                                                width: '20px',
                                                marginRight: '10px',
                                                verticalAlign: 'middle',
                                            }}
                                        />
                                        {player.player_name}
                                    </a>
                                </td>
                                <td
                                    style={{
                                        ...textStyleBold,
                                        color: '#430473',
                                        padding: '10px',
                                        width: '20%',
                                        textAlign: 'left',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <a
                                        href={`/takimlar/${formatTeamNameForURL(
                                            player.team_name
                                        )}`}
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        {player.team_name}
                                    </a>
                                </td>
                                <td
                                    style={{
                                        ...textStyleBold,
                                        padding: '10px',
                                        width: '5%',
                                    }}
                                >
                                    {player.total_matches}
                                </td>
                                <td
                                    style={{
                                        ...textStyleBold,
                                        padding: '10px',
                                        width: '6%',
                                    }}
                                >
                                    {selectedTable === 'value'
                                        ? (
                                            player.total_points /
                                            player.total_matches
                                        ).toFixed(2)
                                        : (
                                            player.total_goals /
                                            player.total_matches
                                        ).toFixed(2)}
                                </td>
                                <td
                                    style={{
                                        ...textStyleBold,
                                        padding: '10px',
                                        width: '6%',
                                    }}
                                >
                                    {selectedTable === 'value'
                                        ? player.value
                                        : player.total_goals}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                />
            </div>

            <Footer />
        </div>
    );
};

const tableMatchesStyle = {
    width: '120%',
    borderCollapse: 'collapse',
    backgroundColor: 'white',

    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '1px',
    boxSizing: 'border-box',
    display: 'block',
};


const textStyleBold = {
    fontStyle: 'Posterama 1927 SemiBold',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
};

function formatTeamNameForURL(teamName) {
    return teamName
        .toLowerCase()
        .replace(/ç/g, "c")
        .replace(/ğ/g, "g")
        .replace(/ı/g, "i")
        .replace(/ö/g, "o")
        .replace(/ş/g, "s")
        .replace(/ü/g, "u")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
        .replace(/\s+/g, "-");
}

export default PlayerListDouble;
