import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Add_User.css';

const AddUser = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('editor');
  const [message, setMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [showPassword, setShowPassword] = useState(false); // Şifre görünürlüğünü yönetir

  useEffect(() => {
    fetchUsers();
  }, []);

  // Kullanıcıları yükleme
  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://elit-lig-back-end-bc5730e96b98.herokuapp.com/api/users', {
        withCredentials: true,
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Kullanıcılar yüklenirken bir hata oluştu:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://elit-lig-back-end-bc5730e96b98.herokuapp.com/api/users/register',
        {
          username,
          password,
          role,
        },
        {
          withCredentials: true,
        }
      );

      setMessage(`Kullanıcı başarıyla kaydedildi: ${response.data.user.username}`);
    } catch (error) {
      setMessage('Kullanıcı kaydedilirken bir hata oluştu.');
      console.error(error);
    }
  };

  // Kullanıcı silme
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Bu kullanıcıyı silmek istediğinize emin misiniz?');
    if (confirmDelete) {
      try {
        await axios.delete(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/api/users/${id}`, {
          withCredentials: true,
        });
        setMessage('Kullanıcı başarıyla silindi.');
        fetchUsers(); // Listeyi güncelle
      } catch (error) {
        setMessage('Kullanıcı silinirken bir hata oluştu.');
        console.error(error);
      }
    }
  };

  return (
    <div className="add-user-container">
      <h2>Kullanıcı Ekle</h2>
      <form className="add-user-form" onSubmit={handleSubmit}>
        <label htmlFor="username">Kullanıcı Adı:</label>
        <input
          id="username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />

        <label htmlFor="password">Şifre:</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            id="password"
            type={showPassword ? 'text' : 'password'} // Şifre görünürlüğü
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{ flex: '1' }}
          />
          <button
            type="button"
            onClick={() => setShowPassword((prev) => !prev)} // Şifre görünürlüğünü toggle eder
            style={{
              marginLeft: '10px',
              padding: '5px 10px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            {showPassword ? 'Gizle' : 'Göster'}
          </button>
        </div>

        <label htmlFor="role">Rol:</label>
        <select
          id="role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          required
        >
          <option value="admin">Admin</option>
          <option value="editor">Editor</option>
        </select>

        <button type="submit" className="add-user-button">
          Kaydet
        </button>
      </form>
      {message && <p className="message">{message}</p>}

      <h2>Kullanıcılar</h2>
      <div className="user-list-container">
        <table className="user-table">
          <thead>
            <tr>
              <th>Kullanıcı Adı</th>
              <th>Rol</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.username}</td>
                <td>{user.role}</td>
                <td>
                  <button
                    className="add-user-delete-button"
                    onClick={() => handleDelete(user.id)}
                  >
                    Sil
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddUser;
