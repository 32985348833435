import './Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
    return (
        <div className="main-content">
            <footer className="site-footer">
                <div className="footer-content">
                    <p className='footer-content-p'>FREELİG</p>
                    <a href='/fiksturlu-puan' className='footer-content-a'>Puan Durumu</a><br />
                    <a href='/freelig-sonuclar' className='footer-content-a'>Sonuçlar</a><br />
                    <a href='/fiksturlu-takimlar' className='footer-content-a'>Takımlar</a><br />
                    <a href='/fiksturlu-cezalar' className='footer-content-a'>Cezalar</a><br />
                    <a href='/fiksturlu-odul' className='footer-content-a'>Ödül Köşesi</a><br />
                </div>
                <div className="footer-content">
                    <p className='footer-content-p'>FİKSTÜRLÜ LİG</p>
                    <a href='/fiksturlu-puan' className='footer-content-a'>Puan Durumu</a><br />
                    <a href='/fiksturlu-sonuclar' className='footer-content-a'>Sonuçlar</a><br />
                    <a href='/fikstur' className='footer-content-a'>Fikstür</a><br />
                    <a href='/fiksturlu-takimlar' className='footer-content-a'>Takımlar</a><br />
                    <a href='/fiksturlu-cezalar' className='footer-content-a'>Cezalar</a><br />
                    <a href='/fiksturlu-odul' className='footer-content-a'>Ödül Köşesi</a><br />
                </div>
                <div className="footer-content">
                    <p className='footer-content-p'>İLETİŞİM</p>
                    <a href="tel:+905071690888" className='footer-content-a'>Bizi Arayın</a><br />
                    <a href="tel:+905071690888" className='footer-content-a'>0507 169 0888</a><br /><br />
                    <a href="tel:+905359352550" className='footer-content-a'>Editör Hattımız</a><br />
                    <a href="tel:+905359352550" className='footer-content-a'>0535 935 2550</a><br /><br />
                    <a href="mailto:destek@elitlig.com?subject=Yardım İstiyorum&body=Merhaba, size nasıl yardımcı olabilirim?" className='footer-content-a'>E-posta</a><br />
                    <a href="mailto:destek@elitlig.com?subject=Yardım İstiyorum&body=Merhaba, size nasıl yardımcı olabilirim?" className='footer-content-a'>destek@elitlig.com</a>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
