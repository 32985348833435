import React from 'react';

const Button = ({ currentPage, totalPages, onPageChange }) => {
    const getPaginationRange = () => {
        const range = [];
        const maxVisiblePages = 3;

        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                range.push(i);
            }
            return range;
        }

        let start = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let end = Math.min(totalPages, start + maxVisiblePages - 1);

        if (end === totalPages) {
            start = Math.max(1, totalPages - maxVisiblePages + 1);
        }

        for (let i = start; i <= end; i++) {
            range.push(i);
        }

        if (start > 2) {  // Changed condition to `> 2` to avoid showing `1 ... 2`
            range.unshift('...');
            range.unshift(1);
        } else if (start === 2) {
            range.unshift(1);
        }
    
        // Adjust for end < totalPages and add '...' at the end
        if (end < totalPages - 1) {
            range.push('...');
            range.push(totalPages);
        } else if (end === totalPages - 1) {
            range.push(totalPages);
        }

        return range;
    };

    const paginationRange = getPaginationRange();

    return (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
            {paginationRange.map((page, index) => (
                <button
                    key={index}
                    onClick={() => {
                        if (typeof page === 'number') {
                            onPageChange(page);
                        }
                    }}
                    disabled={page === '...'}
                    style={{
                        margin: '0 2px',
                        padding: '5px 10px',
                        height: '30px',
                        backgroundColor: currentPage === page ? '#430473' : '#dfcdec',
                        color: currentPage === page ? '#dfcdec' : '#430473',
                        border: '1px solid #430473',
                        borderRadius: '5px',
                        cursor: typeof page === 'number' ? 'pointer' : 'not-allowed',
                    }}
                >
                    {page}
                </button>
            ))}
        </div>
    );
};

export default Button;