import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./Add_Match.css";
import Add_Players from "./Add_Player";
import { useNavigate } from "react-router-dom";
const Add_Match = () => {
    const [matchData, setMatchData] = useState({
        first_team_name: "",
        second_team_name: "",
        first_team_score: null,
        second_team_score: null,
        first_team_percentage: 0,
        second_team_percentage: 0,
        date: "",
        time: "",
        match_field: "Ostim Saha",
        league_name: "",
        match_comment: "",
        match_title: "",
        match_season: "",
        match_video: "",
        match_images: "",
        match_interview: "",
        match_mvp: null,
        is_it_fixture: true, // Yeni alan eklendi
    });

    const [teams, setTeams] = useState([]);// Tum Takimlar
    const [allPlayers, setAllPlayers] = useState([]); // Tüm oyuncular
    const [filteredSeasons, setFilteredSeasons] = useState([]);
    const baseUrl = "https://elit-lig-back-end-bc5730e96b98.herokuapp.com/";
    const navigate = useNavigate();
    
    const [searchTermFirstAll, setSearchTermFirstAll] = useState(""); // Tum takimlardan ilk takım için oyuncu arama
    const [searchTermSecondAll, setSearchTermSecondAll] = useState(""); // Tum takimlardan ikinci takım için oyuncu arama

    const [searchTermFirstPlayer, setSearchTermFirstPlayer] = useState(""); //ilk takim icin oyuncu arama
    const [searchTermSecondPlayer, setSearchTermSecondPlayer] = useState(""); //ikinci takim icin oynuncu arama

    const [showAllPlayersForFirstTeam, setShowAllPlayersForFirstTeam] = useState(false); // Toggle for first team
    const [showAllPlayersForSecondTeam, setShowAllPlayersForSecondTeam] = useState(false); // Toggle for second team

    const [showAddPlayerModalForFirstTeam, setShowAddPlayerModalForFirstTeam] = useState(false); // Ilk takima yeni oyuncu ekleme
    const [showAddPlayerModalForSecondTeam, setShowAddPlayerModalForSecondTeam] = useState(false); // Ikinci takma yeni oyuncu ekleme

    const [firstTeamPlayers, setFirstTeamPlayers] = useState([]); //Ilk takimin oyunculari
    const [secondTeamPlayers, setSecondTeamPlayers] = useState([]); //Ikinci takimin oyunculari

    const [selectedFirstTeamPlayers, setSelectedFirstTeamPlayers] = useState([]); // ilk takim icin secilen oyuncular
    const [selectedSecondTeamPlayers, setSelectedSecondTeamPlayers] = useState([]); // ikinci takim icin secilen oyuncular

    const [filteredFirstTeamPlayers, setFilteredFirstTeamPlayers] = useState([]); //aramayla filtrelenmis ilk takim kadrosu
    const [filteredSecondTeamPlayers, setFilteredSecondTeamPlayers] = useState([]); // aramarlya filtrelenmis ikinici takim kadrosu

    const [filteredAllPlayersForFirst, setfilteredAllPlayersForFirst] = useState([]); //aramayla filtrelenmis tum oyuncular ilk takim icin
    const [filteredAllPlayersForSecond, setfilteredAllPlayersForSecond] = useState([]); // aramarlya filtrelenmis tum oyuncular ikinci takim icin
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [isButtonVisibleFix, setIsButtonVisibleFix] = useState(true);


    const [fields, setFields] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [seasons, setSeasons] = useState([]);

    const [playerStatistics, setPlayerStatistics] = useState({});

    const [isFixtureLeague, setIsFixtureLeague] = useState(true); // ligin fikstur olup olmadigini kontrol ediyor
    const [searchTermFirst, setSearchTermFirst] = useState(""); // İlk takım arama terimi
    const [searchTermSecond, setSearchTermSecond] = useState(""); //Ikinci takim icin arama terimi

    const [isStatsUpdated, setIsStatsUpdated] = useState(false); // Yeni state tanımlandı

    // Checkbox selection logic
    const isSelected = (playerId, isFirstTeam) => { //Bu checkbox lari duzenlemek icin gerekli
        return isFirstTeam
            ? selectedFirstTeamPlayers.includes(playerId)
            : selectedSecondTeamPlayers.includes(playerId);
    };

    // Generic filter logic
    const filterPlayers = (players, searchTerm) => //Oyuncu Filtreleme
        players.filter((player) =>
            player.player_name.toLowerCase().includes(searchTerm.toLowerCase())
        );

    useEffect(() => {
        if (matchData.league_name) {
            // Eğer bir lig seçildiyse, o lige ait sezonları filtrele
            const filtered = seasons.filter(season => season.league_name === matchData.league_name);
            setFilteredSeasons(filtered);
        } else {
            // Eğer lig seçilmediyse, tüm sezonları göster
            setFilteredSeasons(seasons);
        }
    }, [matchData.league_name, seasons]);

    // Update filtered players for the first team
    useEffect(() => {
        setFilteredFirstTeamPlayers(filterPlayers(firstTeamPlayers, searchTermFirstPlayer));
    }, [searchTermFirstPlayer, firstTeamPlayers]);

    // Update filtered players for the second team
    useEffect(() => {
        setFilteredSecondTeamPlayers(filterPlayers(secondTeamPlayers, searchTermSecondPlayer));
    }, [searchTermSecondPlayer, secondTeamPlayers]);

    // Update "All Players" filtering for the first team
    useEffect(() => {
        setfilteredAllPlayersForFirst(filterPlayers(allPlayers, searchTermFirstAll));
    }, [searchTermFirstAll, allPlayers]);

    // Update "All Players" filtering for the second team
    useEffect(() => {
        setfilteredAllPlayersForSecond(filterPlayers(allPlayers, searchTermSecondAll));
    }, [searchTermSecondAll, allPlayers]);

    // Takımlar, Sahalar, Ligler, Sezonlar
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [teamsResponse, fieldsResponse, leaguesResponse, seasonsResponse, playerTeamsResponse] = await Promise.all([
                    axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar"),
                    axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sahalar"),
                    axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler"),
                    axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar"),
                    axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari")
                ]);

                setTeams(teamsResponse.data);
                setFields(fieldsResponse.data);
                setLeagues(leaguesResponse.data);
                setSeasons(seasonsResponse.data);

                // Deduplicate players by player_id
                const uniquePlayers = Array.from(
                    new Map(playerTeamsResponse.data.map(player => [player.player_id, player])).values()
                );

                setAllPlayers(uniquePlayers); // Set the deduplicated list of players
                // Tüm oyuncular için duplicate kayıtları filtrele

            } catch (err) {
                console.error("Error fetching data", err);
            }
        }
        fetchData();
    }, [searchTermFirstPlayer, searchTermSecondPlayer, firstTeamPlayers, secondTeamPlayers]);


    // Filtreleme
    const filteredFirstTeams = teams.filter((team) =>
        team.team_name.toLowerCase().includes(searchTermFirst.toLowerCase())
    );

    const filteredSecondTeams = teams.filter((team) =>
        team.team_name.toLowerCase().includes(searchTermSecond.toLowerCase())
    );


    // Ligin Fiksturlu Olduguna Bak
    const handleChange = (e) => {
        const { name, value } = e.target;
        setMatchData((prevData) => ({ ...prevData, [name]: value }));
        // Lig seçimine göre dinamik form kontrolü

    };

    // Checkbox durumuna göre `is_it_fixture` değişir
    const handleCheckboxChange = () => {
        // Checkbox değişiminde hem `isFixtureLeague` hem de `matchData.is_it_fixture` güncellenir
        setIsFixtureLeague((prev) => !prev);
        setMatchData((prevData) => ({
            ...prevData,
            is_it_fixture: !prevData.is_it_fixture,
        }));
    };

    // Maci Kaydet
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!matchData.date || !matchData.time || !matchData.league_name || !matchData.match_season) {
            alert("Lütfen tarih, saat, lig ve sezon bilgilerini doldurun.");
            return;
            
            }
        
        if (isFixtureLeague) {
            // Sadece gerekli bilgileri gönder
            const { first_team_name, second_team_name, date, time, match_season, league_name } = matchData;
            const minimalData = { first_team_name, second_team_name, date, time, match_season, league_name };

            try {
                const response = await axios.post("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/add-fikstur", minimalData);
                const newMatchId = response.data.id;
                alert("Maç Eklendi.");
                navigate("/editor/maclar")
                return newMatchId;
               
            } catch (err) {
                console.error("Error adding match", err);
                alert("Maç eklenirken hata oluştu.");
            }
        } else {
            // Tüm form verilerini gönder
            try {
                const response = await axios.post("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/add", matchData);
                // burda veriler giderken team scorelarin da gitmesi lazim
                const newMatchId = response.data.id;
                return newMatchId;
            } catch (err) {
                console.error("Error adding match", err);
                alert("Maç eklenirken hata oluştu.");
            }
        }
    };

    // Takim Secimi
    const handleTeamSelection = async (teamId, isFirstTeam) => {
        const selectedTeam = teams.find(team => team.id === parseInt(teamId));

        if (!selectedTeam) {
            alert("Seçilen takım bulunamadı.");
            return;
        }

        if (isFirstTeam) {
            if (matchData.second_team_name && matchData.second_team_name === selectedTeam.team_name) {
                alert("Aynı takımı iki kez seçemezsiniz!");
                return;
            }

            // Fetch players for the first team and update matchData
            try {
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/${teamId}`);
                setFirstTeamPlayers(response.data);
                setMatchData({
                    ...matchData,
                    first_team_name: selectedTeam.team_name,
                });
                setSearchTermFirst(selectedTeam.team_name); // İlk takım için arama terimini güncelle
            } catch (error) {
                console.error("Error fetching first team players:", error);
            }
        } else {
            if (matchData.first_team_name && matchData.first_team_name === selectedTeam.team_name) {
                alert("Aynı takımı iki kez seçemezsiniz!");
                return;
            }

            // Fetch players for the second team and update matchData
            try {
                const response = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/${teamId}`);
                setSecondTeamPlayers(response.data);
                setMatchData({
                    ...matchData,
                    second_team_name: selectedTeam.team_name,
                });
                setSearchTermSecond(selectedTeam.team_name); // İkinci takım için arama terimini güncelle
            } catch (error) {
                console.error("Error fetching second team players:", error);
            }
        }
    };


    /*const handlePlayerSelection = (playerId, isFirstTeam) => {
        if (isFirstTeam) {
            // Eğer oyuncu ikinci takımda seçilmişse, uyarı göster
            if (selectedSecondTeamPlayers.includes(playerId)) {
                alert("Aynı oyuncu iki takımda birden oynayamaz!");
                return;
            }
            setSelectedFirstTeamPlayers((prev) =>
                prev.includes(playerId) ? prev.filter((id) => id !== playerId) : [...prev, playerId]
            );
        } else {
            // Eğer oyuncu ilk takımda seçilmişse, uyarı göster
            if (selectedFirstTeamPlayers.includes(playerId)) {
                alert("Aynı oyuncu iki takımda birden oynayamaz!");
                return;
            }
            setSelectedSecondTeamPlayers((prev) =>
                prev.includes(playerId) ? prev.filter((id) => id !== playerId) : [...prev, playerId]
            );
        }
    };
    
    const handlePlayerSelectionFromAll = (playerId, isFirstTeam) => {
        if (isFirstTeam) {
            // Eğer oyuncu ikinci takımda seçilmişse, uyarı göster
            if (selectedSecondTeamPlayers.includes(playerId)) {
                alert("Aynı oyuncu iki takımda birden oynayamaz!");
                return;
            }
            setSelectedFirstTeamPlayers((prev) => {
                if (prev.includes(playerId)) {
                    return prev.filter((id) => id !== playerId); // Remove if already selected
                } else {
                    return [...prev, playerId]; // Add if not selected
                }
            });
        } else {
            // Eğer oyuncu ilk takımda seçilmişse, uyarı göster
            if (selectedFirstTeamPlayers.includes(playerId)) {
                alert("Aynı oyuncu iki takımda birden oynayamaz!");
                return;
            }
            setSelectedSecondTeamPlayers((prev) => {
                if (prev.includes(playerId)) {
                    return prev.filter((id) => id !== playerId); // Remove if already selected
                } else {
                    return [...prev, playerId]; // Add if not selected
                }
            });
        }
    };    
    */

    const handlePlayerSelection = (playerId, isFirstTeam) => {
        if (isFirstTeam) {
            // Eğer oyuncu ikinci takımda seçilmişse, uyarı göster
            if (selectedSecondTeamPlayers.includes(playerId)) {
                alert("Aynı oyuncu iki takımda birden oynayamaz!");
                return;
            }
            setSelectedFirstTeamPlayers((prev) => {
                const updatedSet = new Set(prev); // Mevcut listeyi Set yapısına dönüştür
                if (updatedSet.has(playerId)) {
                    updatedSet.delete(playerId); // Seçiliyse çıkar
                } else {
                    updatedSet.add(playerId); // Seçili değilse ekle
                }
                return Array.from(updatedSet); // Set'i tekrar diziye dönüştür
            });
        } else {
            // Eğer oyuncu ilk takımda seçilmişse, uyarı göster
            if (selectedFirstTeamPlayers.includes(playerId)) {
                alert("Aynı oyuncu iki takımda birden oynayamaz!");
                return;
            }
            setSelectedSecondTeamPlayers((prev) => {
                const updatedSet = new Set(prev);
                if (updatedSet.has(playerId)) {
                    updatedSet.delete(playerId);
                } else {
                    updatedSet.add(playerId);
                }
                return Array.from(updatedSet);
            });
        }
    };

    const handlePlayerSelectionFromAll = (playerId, isFirstTeam) => {
        if (isFirstTeam) {
            // Eğer oyuncu ikinci takımda seçilmişse, uyarı göster
            if (selectedSecondTeamPlayers.includes(playerId)) {
                alert("Aynı oyuncu iki takımda birden oynayamaz!");
                return;
            }
            setSelectedFirstTeamPlayers((prev) => {
                const updatedSet = new Set(prev);
                if (updatedSet.has(playerId)) {
                    updatedSet.delete(playerId); // Seçiliyse çıkar
                } else {
                    updatedSet.add(playerId); // Seçili değilse ekle
                }
                return Array.from(updatedSet);
            });
        } else {
            // Eğer oyuncu ilk takımda seçilmişse, uyarı göster
            if (selectedFirstTeamPlayers.includes(playerId)) {
                alert("Aynı oyuncu iki takımda birden oynayamaz!");
                return;
            }
            setSelectedSecondTeamPlayers((prev) => {
                const updatedSet = new Set(prev);
                if (updatedSet.has(playerId)) {
                    updatedSet.delete(playerId);
                } else {
                    updatedSet.add(playerId);
                }
                return Array.from(updatedSet);
            });
        }
    };

    const updateMatchScores = async (matchId, firstTeamScore, secondTeamScore) => {
        try {
            // Match verisini güncellemek için API çağrısı
            const updatedData = {
                first_team_score: firstTeamScore,
                second_team_score: secondTeamScore,
            };

            const response = await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/${matchId}`, updatedData);

        } catch (error) {
            console.error("Error updating match scores:", error);
            alert("An error occurred while updating match scores. Please try again.");
        }
    };

    const handleAddPlayerStatistics = async (matchId) => {
        try {
            if (!isFixtureLeague) {
                // İlk ve ikinci takımın toplam gollerini hesapla
                const firstTeamGoals = selectedFirstTeamPlayers.reduce((total, playerId) => {
                    return total + (playerStatistics[playerId]?.goals || 0);
                }, 0);

                const secondTeamGoals = selectedSecondTeamPlayers.reduce((total, playerId) => {
                    return total + (playerStatistics[playerId]?.goals || 0);
                }, 0);

                // Skorları API'ye kaydet
                await updateMatchScores(matchId, firstTeamGoals, secondTeamGoals);

                // Güncel skorları local değişkenlerde tut
                const updatedMatchData = {
                    ...matchData,
                    first_team_score: firstTeamGoals,
                    second_team_score: secondTeamGoals,
                };

                 // Takım adlarından ID'leri bulma 
                
                const firstTeam = teams.find(team => team.team_name === matchData.first_team_name);
                const secondTeam = teams.find(team => team.team_name === matchData.second_team_name);

                const firstTeamId = firstTeam.id;
                const secondTeamId = secondTeam.id;
                console.log("İlk Takım ID:", firstTeamId);
                console.log("İkinci Takım ID:", secondTeamId);


                const statisticsEntries = [];
                for (const playerId of [...selectedFirstTeamPlayers, ...selectedSecondTeamPlayers]) {
                    const playerStats = playerStatistics[playerId];
                    if (!playerStats) continue;

                    // Takım ID'lerini belirleme
                    const isPlayerInFirstTeam = selectedFirstTeamPlayers.includes(playerId);
                    const teamId = isPlayerInFirstTeam ? firstTeamId : secondTeamId;
                    const opponentTeamId = isPlayerInFirstTeam ? secondTeamId : firstTeamId;

                    // Verileri sayısal formata dönüştürme
                    const statsData = {
                        player_id: playerId,
                        match_id: matchId,
                        team_id: teamId,
                        opponent_team_id: opponentTeamId,
                        number_of_goals: parseInt(playerStats.goals, 10) || 0,
                        goals_minutes: playerStats.goals_minutes || '',
                        yellow_card: parseInt(playerStats.yellow_card, 10) || 0,
                        red_card: parseInt(playerStats.red_card, 10) || 0,
                        points: parseInt(playerStats.points, 10) || 0,
                        goal_to_himself: parseInt(playerStats.goal_to_himself, 10) || 0,
                        goal_to_himself_minutes: playerStats.goal_to_himself_minutes || '',
                        player_position: playerStats.position || ''
                    };

                    statisticsEntries.push(statsData);
                }

                // Maç sonucunu belirle
                const { winnerTeamId, loserTeamId, draw } = determineMatchOutcome(
                    updatedMatchData.first_team_score,
                    updatedMatchData.second_team_score,
                    firstTeamId,
                    secondTeamId
                );

                // Takım istatistiklerini güncelle
                try {
                   await updateTeamStatistics(
                        firstTeamId,
                        secondTeamId,
                        updatedMatchData.first_team_score,
                        updatedMatchData.second_team_score,
                        winnerTeamId === firstTeamId,
                        draw
                    );
                    await updateTeamStatistics(
                        secondTeamId,
                        firstTeamId,
                        updatedMatchData.second_team_score,
                        updatedMatchData.first_team_score,
                        winnerTeamId === secondTeamId,
                        draw
                    );
                } catch (err) {
                    console.error("Takım istatistikleri güncellenirken hata oluştu:", err);
                }

                console.log("Winner Team ID:", winnerTeamId);
                console.log("Loser Team ID:", loserTeamId);
                console.log("Draw:", draw);

                console.log("Payload being sent:", statisticsEntries);


                for (const entry of statisticsEntries) {
                    try {
                        await axios.post("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-istatistikleri/", entry);

                        // Her bir entry için updatePlayerStatistics çağrısı yap
                        try {
                            await updatePlayerStatistics(entry.player_id, entry, winnerTeamId, loserTeamId, draw);

                        } catch (err) {
                            console.error("Error updating player statistics for entry:", entry, err);
                        }

                    } catch (err) {
                        console.error("Error adding player statistics for entry:", entry, err);
                    }
                }

            }
        } catch (err) {
            console.error("Error adding player statistics", err);
            alert("An error occurred while adding player statistics.");
        }
    };

    const updatePlayerStatistics = async (playerId, statsData, winnerTeamId, loserTeamId, draw) => {
        try {
            // Oyuncunun bilgilerini Players tablosundan çek
            const playerResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${playerId}`);
            const playerData = playerResponse.data[0];

            // Yeni değerleri hesapla
            const howManyYellowCard = statsData.yellow_card !== 0 ? 1 : 0;
            const howManyRedCard = statsData.red_card !== 0 ? 1 : 0;

            const updatedData = {
                total_matches: playerData.total_matches + 1,
                total_goals: playerData.total_goals + (statsData.number_of_goals),
                total_points: playerData.total_points + (statsData.points),
                total_yellow_cards: playerData.total_yellow_cards + howManyYellowCard,
                total_red_cards: playerData.total_red_cards + howManyRedCard,
            };

            // Maç sonucu kontrolü
            if (draw) {
                updatedData.draws = playerData.draws + 1;
            } else if (statsData.team_id === winnerTeamId) {
                updatedData.wins = playerData.wins + 1;
            } else if (statsData.team_id === loserTeamId) {
                updatedData.losses = playerData.losses + 1;
            }

            // Oyuncunun bilgilerini güncelle
            await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular/${playerId}`, updatedData);
        } catch (err) {
            console.error("Error updating player statistics:", err);
        }
    };

    const updateTeamStatistics = async (teamId, opponentTeamId, teamGoals, opponentGoals, isWinner, isDraw) => {
        try {
            // Takım bilgilerini Teams tablosundan çek
            const teamResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${teamId}`);
            const teamData = teamResponse.data;

            const opponentResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${opponentTeamId}`);
            const opponentData = opponentResponse.data;

            // Güncellenecek verileri hazırlama
            const updatedData = {
                total_matches: teamData.total_matches + 1,
                goals_scored: teamData.goals_scored + teamGoals,
                goals_conceded: teamData.goals_conceded + opponentGoals,

            };

            // Maç sonucuna göre güncellemeler
            if (isDraw) {
                updatedData.team_draws = teamData.team_draws + 1;
            } else if (isWinner) {
                updatedData.team_wins = teamData.team_wins + 1;
            } else {
                updatedData.team_losses = teamData.team_losses + 1;
            }

            // Takım bilgilerini güncelle
            await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${teamId}`, updatedData);

        } catch (err) {
            console.error("Takım bilgileri güncellenirken hata oluştu:", err);
        }
    }

    const determineMatchOutcome = (updatedFirstScore, updatedSecondScore, first_team_id, second_team_id) => {
        const firstTeamScore = updatedFirstScore;
        const secondTeamScore = updatedSecondScore;
        console.log("ilk takim skor: ", updatedFirstScore, "ikinci takim skor: ", updatedSecondScore);
        let winnerTeamId = null;
        let loserTeamId = null;
        let draw = false;

        if (firstTeamScore > secondTeamScore) {
            winnerTeamId = first_team_id;
            loserTeamId = second_team_id;
        } else if (secondTeamScore > firstTeamScore) {
            winnerTeamId = second_team_id;
            loserTeamId = first_team_id;
        } else {
            draw = true;
        }

        return { winnerTeamId, loserTeamId, draw };
    };

    
    const handlePlayerStatsChange = (playerId, field, value) => {
        if (value < 0) {
            alert("Negatif değer giremezsiniz.");
            return;
        }
    
        setPlayerStatistics((prevState) => {
            const updatedStatistics = {
                ...prevState,
                [playerId]: {
                    ...prevState[playerId],
                    [field]: value,
                },
            };
            return updatedStatistics;
        });
    };
    

    useEffect(() => {
        const saveMatchAndStatistics = async () => {
            if (isStatsUpdated) {
                try {
                    if (!matchData.date || !matchData.time || !matchData.league_name || !matchData.match_season) {
                        alert("Lütfen tarih, saat, lig ve sezon bilgilerini doldurun.");
                        return;
                    }
                    // Yeni maç ID'sini al ve istatistikleri kaydet
                    const newMatchId = await handleSubmit({ preventDefault: () => { } });
                    await handleAddPlayerStatistics(newMatchId);
                    navigate("/editor/maclar");
                    alert("Maç ve oyuncu istatistikleri başarıyla kaydedildi!");
                } catch (error) {
                    console.error("Maç ve oyuncu istatistikleri kaydedilirken hata oluştu:", error);
                    alert("Bir hata oluştu. Lütfen tekrar deneyin.");
                } finally {
                    setIsStatsUpdated(false); // İşlem tamamlandıktan sonra flag'i sıfırla
                }
            }
        };

        saveMatchAndStatistics();
    }, [isStatsUpdated, playerStatistics]);

    /*const handleSaveMatchAndStatistics = async () => {
        try {
            // Önce handleSubmit çalıştır ve dönen matchId'yi al
            const newMatchId = await handleSubmit({ preventDefault: () => { } });

            // Ardından handleAddPlayerStatistics çalıştır ve matchId'yi ilet
            await handleAddPlayerStatistics(newMatchId);

            alert("Maç ve oyuncu istatistikleri başarıyla kaydedildi!");
        } catch (error) {
            console.error("Maç ve oyuncu istatistikleri kaydedilirken hata oluştu:", error);
            alert("Bir hata oluştu. Lütfen tekrar deneyin.");
        }
    };*/
    const handleSaveMatchAndStatistics = async () => {
        // Güncelleme tamamlandığında işlemleri tetiklemek için bir state kullanıyoruz
        setIsStatsUpdated(false); // Güncelleme başlıyor

        const allSelectedPlayers = [
            ...selectedFirstTeamPlayers,
            ...selectedSecondTeamPlayers,
        ];

         // Eksik istatistikleri tamamla
        allSelectedPlayers.forEach((playerId) => {
            ['goals', 'points', 'yellow_card', 'red_card', 'goals_minutes'].forEach((field) => {
                const currentPlayerStats = playerStatistics[playerId] || {};
                if (currentPlayerStats[field] === undefined || currentPlayerStats[field] === null) {
                    handlePlayerStatsChange(playerId, field, field === 'goals_minutes' ? '' : 0);
                }
            });
        });

        // Tüm güncellemeler tamamlandıktan sonra isStatsUpdated flag'ini true yap
        setTimeout(() => {
            setIsStatsUpdated(true);
        }, 0); // React state güncellemesini beklemek için
    };





    const refreshPlayers = async (newPlayerIds = [], isFirstTeam = true) => {
        if (isFirstTeam && matchData.first_team_name) {
            const firstTeam = teams.find(
                (team) => team.team_name === matchData.first_team_name
            );
            if (firstTeam) {
                try {
                    const response = await axios.get(
                        `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/${firstTeam.id}`
                    );
                    const updatedPlayers = response.data;
                    setFirstTeamPlayers(updatedPlayers);

                    // Yeni eklenen oyuncuları seçili hale getir
                    setSelectedFirstTeamPlayers((prevSelected) => {
                        const allSelected = [
                            ...prevSelected,
                            ...newPlayerIds,
                        ];
                        return [...new Set(allSelected)]; // Benzersiz liste oluştur
                    });
                } catch (error) {
                    console.error("Error refreshing first team players:", error);
                }
            }
        }

        if (!isFirstTeam && matchData.second_team_name) {
            const secondTeam = teams.find(
                (team) => team.team_name === matchData.second_team_name
            );
            if (secondTeam) {
                try {
                    const response = await axios.get(
                        `https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/${secondTeam.id}`
                    );
                    const updatedPlayers = response.data;
                    setSecondTeamPlayers(updatedPlayers);

                    // Yeni eklenen oyuncuları seçili hale getir
                    setSelectedSecondTeamPlayers((prevSelected) => {
                        const allSelected = [
                            ...prevSelected,
                            ...newPlayerIds,
                        ];
                        return [...new Set(allSelected)]; // Benzersiz liste oluştur
                    });
                } catch (error) {
                    console.error("Error refreshing second team players:", error);
                }
            }
        }
    };



    // Tüm seçilen oyuncuları tek bir listede toplama
    const allSelectedPlayers = [
        ...allPlayers.filter(player => selectedFirstTeamPlayers.includes(player.player_id)),
        ...allPlayers.filter(player => selectedSecondTeamPlayers.includes(player.player_id))
    ];


    // Filter first and second team players and all players in separate useEffects
    useEffect(() => {
        const filteredFirst = firstTeamPlayers
            .filter((player) => player.player_name.toLowerCase().includes(searchTermFirstPlayer.toLowerCase()))
            .sort((a, b) => a.player_name.localeCompare(b.player_name)); // Sort alphabetically
        setFilteredFirstTeamPlayers(filteredFirst);
    }, [searchTermFirstPlayer, firstTeamPlayers]);

    useEffect(() => {
        const filteredSecond = secondTeamPlayers
            .filter((player) => player.player_name.toLowerCase().includes(searchTermSecondPlayer.toLowerCase()))
            .sort((a, b) => a.player_name.localeCompare(b.player_name)); // Sort alphabetically
        setFilteredSecondTeamPlayers(filteredSecond);
    }, [searchTermSecondPlayer, secondTeamPlayers]);

    useEffect(() => {
        const filteredAllFirst = allPlayers.filter(player =>
            player.player_name.toLowerCase().includes(searchTermFirstAll.toLowerCase())
        );
        setfilteredAllPlayersForFirst(filteredAllFirst);
    }, [searchTermFirstAll, allPlayers]);

    useEffect(() => {
        const filteredAllSecond = allPlayers.filter(player =>
            player.player_name.toLowerCase().includes(searchTermSecondAll.toLowerCase())
        );
        setfilteredAllPlayersForSecond(filteredAllSecond);
    }, [searchTermSecondAll, allPlayers]);

    const handleKeyNavigation = (e, rowIndex, colIndex, tableId) => {
        const keyCode = e.keyCode || e.which;
        let nextCell;

        if (keyCode === 37) { // Sol ok
            nextCell = document.querySelector(`[data-table="${tableId}"][data-row="${rowIndex}"][data-col="${colIndex - 1}"]`);
        } else if (keyCode === 39) { // Sağ ok
            nextCell = document.querySelector(`[data-table="${tableId}"][data-row="${rowIndex}"][data-col="${colIndex + 1}"]`);
        }

        if (nextCell) {
            e.preventDefault(); // Varsayılan davranışı engelle
            const input = nextCell.querySelector("input, select, textarea");
            if (input) {
                input.focus(); // Input'a odaklan
                if (input.tagName === "INPUT" || input.tagName === "TEXTAREA") {
                    input.select(); // Metni seçili hale getir
                }
            } else {
                nextCell.focus(); // Hücrenin kendisine odaklan
            }
        }
    };



    return (
        <div className='add-match-container'>
            <h2 className="add-match-title" >Maç Ekle</h2>
            <form >
                {/* İlk Takım Seçimi */}
                <div className="add-match-form-group" >
                    <label className="add-match-label" >{matchData.first_team_name} Takım</label>
                    <input
                        type="text"
                        placeholder="İlk Takım adını yazın"
                        value={searchTermFirst}
                        onChange={(e) => setSearchTermFirst(e.target.value)}
                        className="add-match-team-search-input"
                    />
                     <div className="add-match-scrollable">       
                    <ul className="add-match-team-list">
                        {filteredFirstTeams.map((team) => (
                            <li
                                key={team.id}
                                onClick={() => handleTeamSelection(team.id, true)}
                                className={`add-match-team-list-item ${matchData.first_team_name === team.team_name ? "selected" : ""
                                    }`}
                            >
                                {team.team_name}
                            </li>
                        ))}
                    </ul>
                    </div>

                </div>

                {/* İkinci Takım Seçimi */}
                <div className="add-match-form-group">
                    <label className="add-match-label" >{matchData.second_team_name} Takım</label>
                    <input
                        type="text"
                        placeholder="İkinci Takım adını yazın"
                        value={searchTermSecond}
                        onChange={(e) => setSearchTermSecond(e.target.value)}
                        className="add-match-team-search-input"
                    />
                    <div className="add-match-scrollable">  
                    <ul className="add-match-team-list">
                        {filteredSecondTeams.map((team) => (
                            <li
                                key={team.id}
                                onClick={() => handleTeamSelection(team.id, false)}
                                className={`add-match-team-list-item ${matchData.second_team_name === team.team_name ? "selected" : ""
                                    }`}
                            >
                                {team.team_name}
                            </li>
                        ))}
                    </ul>
                    </div>
                        
                </div>


                <div className="add-match-form-group">
                    <label className="add-match-label" >Tarih</label>
                    <input type="date" placeholder="Tarıhı Secın" name="date" className="add-match-input" value={matchData.date} onChange={handleChange} required />
                </div>

                <div className="add-match-form-group" >
                    <label className="add-match-label" >Saat</label>
                    <input type="time" name="time" className="add-match-input" value={matchData.time} onChange={handleChange} required />
                </div>

                <div className="add-match-form-group">
                <select className="add-match-select" name="league_name" onChange={handleChange} required>
                <option value="">Lig Seç</option>
                {leagues.map((league) => (
                    <option key={league.id} value={league.league_name}>
                        {league.league_name}
                    </option>
                ))}
                </select>
                </div>

                <div className="add-match-form-group">
                    <label className="add-match-label">Sezon Seç</label>
                    <select className="add-match-select" name="match_season" onChange={handleChange} required>
                        <option value="">Sezon Seç</option>
                        {filteredSeasons.map((season) => (
                            <option key={season.id} value={season.season_name}>
                                {season.season_name}
                            </option>
                        ))}
                    </select>
                </div>


                

                {/* Fikstür Durumu */}
                <div className="add-match-form-group">
                    <label className="add-match-checkbox-container">
                        <input
                            type="checkbox"
                            checked={!isFixtureLeague}
                            onChange={handleCheckboxChange} // Hem `isFixtureLeague` hem de `matchData` güncellenir
                        />
                        Maçı Şimdi Yayınla!
                    </label>
                </div>


                <div className="add-match-team-box">
                    {!isFixtureLeague  && (
                        <div className="add-match-team-section">
                            <label className='add-match-label'>{matchData.first_team_name} Oyuncuları</label>

                            <input
                                type="text"
                                placeholder="İlk Takım oyuncusu arayın"
                                value={searchTermFirstPlayer}
                                onChange={(e) => setSearchTermFirstPlayer(e.target.value)}
                                className="add-match-player-search-input"
                            />
                            <ul className="add-match-player-list add-match-scrollable">
                                {filteredFirstTeamPlayers.map((player) => (
                                    <li key={player.player_id} className="add-match-player-list-item">
                                        <input
                                            type="checkbox"
                                            className='add-match-input'
                                            checked={isSelected(player.player_id, true)}
                                            onChange={() => {
                                                handlePlayerSelection(player.player_id, true);
                                            }}
                                        />
                                        {player.player_name}
                                    </li>
                                ))}
                            </ul>

                            {/*Ilk takima tum oyunculardan oyuncu secmek*/}
                            <button
                                className={`add-match-toggle-button ${showAllPlayersForFirstTeam ? "open" : ""}`}
                                onClick={() =>
                                    setShowAllPlayersForFirstTeam(prev => !prev)
                                }
                            >
                                <span className="arrow"></span>
                                {showAllPlayersForFirstTeam ? "Tüm Oyunculardan Seçimi Kapat" : "Tüm Oyunculardan Seç"}
                            </button>

                            {showAllPlayersForFirstTeam && (
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Oyuncu ara"
                                        value={searchTermFirstAll}
                                        onChange={(e) => setSearchTermFirstAll(e.target.value)}
                                        className="add-match-player-search-input"
                                    />
                                    <ul className="add-match-player-list add-match-scrollable">
                                        {filteredAllPlayersForFirst.map(player => (
                                            <li key={player.player_id} className="add-match-player-list-item">
                                                <input
                                                    type="checkbox"
                                                    className="add-match-player-search-input"
                                                    checked={isSelected(player.player_id, true)}
                                                    onChange={() => handlePlayerSelectionFromAll(player.player_id, true)}
                                                />
                                                {player.player_name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <button
                                className="add-match-add-player-button"
                                onClick={() => setShowAddPlayerModalForFirstTeam(true)}
                            >
                                Yeni Oyuncu Ekle
                            </button>
                            {/* Modal for İlk Takım */}
                            {showAddPlayerModalForFirstTeam && (
                                <Add_Players
                                    closeModal={() => setShowAddPlayerModalForFirstTeam(false)}
                                    refreshTeams={refreshPlayers}
                                    onPlayerAdded={(newPlayerIds) =>
                                        refreshPlayers(newPlayerIds, true) // İlk takıma yeni oyuncuları ekle
                                    }
                                    defaultTeam={matchData.first_team_name}
                                />
                            )}
                        </div>
                    )}

                    {!isFixtureLeague && (
                        <div className="add-match-team-section">
                            <label className='add-match-label'>{matchData.second_team_name} Takım Oyuncuları</label>
                            <input
                                type="text"
                                placeholder="İkinci Takım oyuncusu arayın"
                                value={searchTermSecondPlayer}
                                onChange={(e) => setSearchTermSecondPlayer(e.target.value)}
                                className="add-match-player-search-input"
                            />
                            <ul className="add-match-player-list add-match-scrollable">
                                {filteredSecondTeamPlayers.map((player) => (
                                    <li key={player.player_id} className="add-match-player-list-item">
                                        <input
                                            type="checkbox"
                                            className="add-match-player-search-input"
                                            checked={isSelected(player.player_id, false)}
                                            onChange={() => {
                                                handlePlayerSelection(player.player_id, false);
                                            }}
                                        />
                                        {player.player_name}
                                    </li>
                                ))}
                            </ul>

                            <button
                                className={`add-match-toggle-button ${showAllPlayersForSecondTeam ? "open" : ""}`}
                                onClick={() =>
                                    setShowAllPlayersForSecondTeam(prev => !prev)
                                }
                            >
                                <span className="arrow"></span>
                                {showAllPlayersForSecondTeam ? "Tüm Oyunculardan Seçimi Kapat" : "Tüm Oyunculardan Seç"}
                            </button>

                            {showAllPlayersForSecondTeam && (
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Oyuncu ara"
                                        value={searchTermSecondAll}
                                        onChange={(e) => setSearchTermSecondAll(e.target.value)}
                                        className="add-match-player-search-input"
                                    />
                                    <ul className="add-match-player-list add-match-scrollable">
                                        {filteredAllPlayersForSecond.map(player => (
                                            <li key={player.player_id} className="add-match-player-list-item">
                                                <input
                                                    type="checkbox"
                                                    className="add-match-player-search-input"
                                                    checked={isSelected(player.player_id, false)}
                                                    onChange={() => handlePlayerSelectionFromAll(player.player_id, false)}
                                                />
                                                {player.player_name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <button
                                className="add-match-add-player-button"
                                onClick={() => setShowAddPlayerModalForSecondTeam(true)}
                            >
                                Yeni Oyuncu Ekle
                            </button>
                            {/* Modal for İlk Takım */}
                            {showAddPlayerModalForSecondTeam && (
                                <Add_Players
                                    closeModal={() => setShowAddPlayerModalForSecondTeam(false)}
                                    refreshTeams={refreshPlayers}
                                    onPlayerAdded={(newPlayerIds) =>
                                        refreshPlayers(newPlayerIds, false) // İkinci takıma yeni oyuncuları ekle
                                    }
                                    defaultTeam={matchData.second_team_name}
                                />
                            )}
                        </div>
                    )}
                </div>

                {!isFixtureLeague && (
                    <>
                        <div className="add-match-form-group" >
                            <label className='add-match-label'>{matchData.first_team_name} Yüzdesi</label>
                            <input
                                type="number"
                                className="add-match-player-search-input"
                                name="first_team_percentage"
                                value={matchData.first_team_percentage}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="add-match-form-group">
                            <label className='add-match-label'>{matchData.second_team_name} Takım Yüzdesi</label>
                            <input
                                type="number"
                                className="add-match-player-search-input"
                                name="second_team_percentage"
                                value={matchData.second_team_percentage}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </>
                )}
                {!isFixtureLeague && (
                    <>
                        <div className="add-match-form-group">
                            <label className='add-match-label' >En Değerli Oyuncu (MVP)</label>
                            <select className="add-match-select" name="match_mvp" onChange={handleChange} required>
                                <option value="">Oyuncu Seç</option>
                                {allSelectedPlayers.map((player) => (
                                    <option key={player.player_id} value={player.player_id}>
                                        {player.player_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                                
                         <div className="add-match-form-group" >
                            <label className='add-match-label'>Maç Başlığı</label>
                            <input
                                type="text" className="add-match-player-search-input"
                                value={matchData.match_title || ""}
                                onChange={(e) => setMatchData({ ...matchData, match_title: e.target.value })}
                            />
                        </div>

                        <div className="add-match-form-group" >
                            <label className='add-match-label'>Maç Yorumu</label>
                            <input
                                type="text" className="add-match-player-search-input"
                                value={matchData.match_comment || ""}
                                onChange={(e) => setMatchData({ ...matchData, match_comment: e.target.value })} />
                        </div>

                        <div className="add-match-form-group">
                            <label className='add-match-label'>Maç Resmi</label>
                            <input type="file"
                                className="add-match-player-search-input"
                                onChange={(e) => setMatchData({ ...matchData, match_picture: e.target.value })} />
                            {matchData.match_picture && (
                                <img
                                    src={`${baseUrl}${matchData.match_picture}`}
                                    alt="Maç Resmi"
                                    style={{ width: '100px', marginTop: '10px' }}
                                />
                            )}
                        </div>

                        <div className="add-match-form-group" >
                            <label className='add-match-label'>Saha</label>
                            <input
                                type="text" className="add-match-player-search-input"
                                value={matchData.match_field || ""}
                                onChange={(e) => setMatchData({ ...matchData, match_field: e.target.value })}
                                 />
                            
                        </div>
                        <div className="add-match-form-group" >
                            <label className='add-match-label'>Maç Görselleri</label>
                            <input
                                type="text" className="add-match-player-search-input"
                                value={matchData.match_images || ""}
                                onChange={(e) => setMatchData({ ...matchData, match_images: e.target.value })}                           
                                 />
                        </div>

                        <div className="add-match-form-group" >
                            <label className='add-match-label'>Maç Videosu</label>
                            <input
                                type="text" className="add-match-player-search-input"
                                value={matchData.match_video || ""}
                                onChange={(e) => setMatchData({ ...matchData, match_video: e.target.value })}                           
                                 />
                        </div>

                        <div className="add-match-form-group" >
                            <label className='add-match-label'>Maç Röportajı</label>
                            <input
                                type="text" className="add-match-player-search-input"
                                value={matchData.match_interview || ""}
                                onChange={(e) => setMatchData({ ...matchData, match_interview: e.target.value })}                            
                                />
                            
                        </div>
                            

                        
                    </>
                )}
                {/* Fikstürlü Lig kontrolü */}
                {isFixtureLeague && isButtonVisibleFix && (
                    <div>
                        <button className='add-match-stat-button'
                            type="submit"
                            onClick={(e) => {
                                handleSubmit(e);
                                setIsButtonVisibleFix(false); // Butonu gizle
                              }}
                        >
                            Maçı Kaydet
                        </button>
                    </div>
                )}

            </form>


            {!isFixtureLeague && (selectedFirstTeamPlayers.length > 0 || selectedSecondTeamPlayers.length > 0) && (
                <div className="add-match-statistics-container">
                    <h2>Oyuncu İstatistikleri Ekle</h2>

                    {/* İlk Takım Oyuncuları Tablosu */}
                   {/* İlk Takım Oyuncuları Tablosu */}
                   <h3>{matchData.first_team_name} Oyuncuları</h3>
                    <table className="add-match-player-statistics-table">
                        <thead>
                            <tr>
                                <th>Oyuncu</th>
                                <th>Mevkii</th>
                                <th>Gol</th>
                                <th>Puan</th>
                                <th>SK</th>
                                <th>KK</th>
                                <th>Gol Dakikaları</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allPlayers
                                .filter((player) => selectedFirstTeamPlayers.includes(player.player_id))
                                .map((player, rowIndex) => (
                                    <tr key={player.player_id}>
                                        <td data-table="first" data-row={rowIndex} data-col={0} tabIndex={0}>
                                            {player.player_name}
                                        </td>
                                        <td data-table="first" data-row={rowIndex} data-col={1} tabIndex={0}>
                                            <select
                                                onKeyDown={(e) =>
                                                    handleKeyNavigation(e, rowIndex, 1,"first")
                                                }
                                                onChange={(e) =>
                                                    handlePlayerStatsChange(player.player_id, "position", e.target.value)
                                                }
                                            >
                                                <option value="">Seç</option>
                                                <option value="orta saha">Orta Saha</option>
                                                <option value="kaleci">Kaleci</option>
                                                <option value="defans">Defans</option>
                                                <option value="forvet">Forvet</option>
                                            </select>
                                        </td>
                                        <td data-table="first" data-row={rowIndex} data-col={2} tabIndex={0}>
                                            <input
                                                type="text"

                                                onKeyDown={(e) =>
                                                    handleKeyNavigation(e, rowIndex, 2,"first")
                                                }
                                                onFocus={(e) => e.target.select()}
                                                defaultValue={0}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "goals", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="first" data-row={rowIndex} data-col={3} tabIndex={0}>
                                            <input
                                                type="text"
                                                onFocus={(e) => e.target.select()}
                                                onKeyDown={(e) =>
                                                    handleKeyNavigation(e, rowIndex, 3,"first")
                                                }
                                                defaultValue={0}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "points", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="first" data-row={rowIndex} data-col={4} tabIndex={0}>
                                            <input
                                                type="text"
                                                onFocus={(e) => e.target.select()}
                                                onKeyDown={(e) =>
                                                    handleKeyNavigation(e, rowIndex, 4,"first")
                                                }
                                                defaultValue={0}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "yellow_card", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="first" data-row={rowIndex} data-col={5} tabIndex={0}>
                                            <input
                                                type="text"
                                                onFocus={(e) => e.target.select()}
                                                onKeyDown={(e) =>
                                                    handleKeyNavigation(e, rowIndex, 5,"first")
                                                }
                                                defaultValue={0}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "red_card", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="first" data-row={rowIndex} data-col={6} tabIndex={0}>
                                            <input
                                                type="text"
                                                onFocus={(e) => e.target.select()}
                                                onKeyDown={(e) =>
                                                    handleKeyNavigation(e, rowIndex, 6,"first")
                                                }
                                                placeholder="16'.. 21' 35"
                                                onChange={(e) =>
                                                    handlePlayerStatsChange(player.player_id, "goals_minutes", e.target.value)
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>

                    {/* İkinci Takım Oyuncuları Tablosu */}
                    {/* İkinci Takım Oyuncuları Tablosu */}
                    <h3>{matchData.second_team_name} Oyuncuları</h3>
                    <table className="add-match-player-statistics-table">
                        <thead>
                            <tr>
                                <th>Oyuncu</th>
                                <th>Mevkii</th>
                                <th>Gol</th>
                                <th>Puan</th>
                                <th>SK</th>
                                <th>KK</th>
                                <th>Gol Dakikaları</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allPlayers
                                .filter((player) => selectedSecondTeamPlayers.includes(player.player_id))
                                .map((player, rowIndex) => (
                                    <tr key={player.player_id}>
                                        <td data-table="second" data-row={rowIndex} data-col={0} tabIndex={0}>
                                            {player.player_name}
                                        </td>
                                        <td data-table="second" data-row={rowIndex} data-col={1} tabIndex={0}>
                                            <select
                                                onKeyDown={(e) => handleKeyNavigation(e, rowIndex, 1,"second")}
                                                onChange={(e) => handlePlayerStatsChange(player.player_id, "position", e.target.value)}
                                            >
                                                <option value="">Seç</option>
                                                <option value="orta saha">Orta Saha</option>
                                                <option value="kaleci">Kaleci</option>
                                                <option value="defans">Defans</option>
                                                <option value="forvet">Forvet</option>
                                            </select>
                                        </td>
                                        <td data-table="second" data-row={rowIndex} data-col={2} tabIndex={0}>
                                            <input
                                                type="text"
                                                defaultValue={0}
                                                onKeyDown={(e) => handleKeyNavigation(e, rowIndex, 2,"second")}
                                                onFocus={(e) => e.target.select()}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "goals", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="second" data-row={rowIndex} data-col={3} tabIndex={0}>
                                            <input
                                                type="text"
                                                defaultValue={0}
                                                onKeyDown={(e) => handleKeyNavigation(e, rowIndex, 3,"second")}
                                                onFocus={(e) => e.target.select()}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "points", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="second" data-row={rowIndex} data-col={4} tabIndex={0}>
                                            <input
                                                type="text"
                                                defaultValue={0}
                                                onKeyDown={(e) => handleKeyNavigation(e, rowIndex, 4,"second")}
                                                onFocus={(e) => e.target.select()}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "yellow_card", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="second" data-row={rowIndex} data-col={5} tabIndex={0}>
                                            <input
                                                type="text"
                                                defaultValue={0}
                                                onKeyDown={(e) => handleKeyNavigation(e, rowIndex, 5,"second")}
                                                onFocus={(e) => e.target.select()}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (/^\d*$/.test(value)) {
                                                        handlePlayerStatsChange(player.player_id, "red_card", parseInt(value, 10) || 0);
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td data-table="second" data-row={rowIndex} data-col={6} tabIndex={0}>
                                            <input
                                                type="text"
                                                placeholder="16'.. 21' 35"
                                                onKeyDown={(e) => handleKeyNavigation(e, rowIndex, 6,"second")}
                                                onFocus={(e) => e.target.select()}
                                                onChange={(e) =>
                                                    handlePlayerStatsChange(player.player_id, "goals_minutes", e.target.value)
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>



                    {/* Kaydetme Butonu */}
                     {isButtonVisible && selectedFirstTeamPlayers.length > 0 && selectedSecondTeamPlayers.length > 0 && (
                        <button
                            className="add-match-stat-button"
                            type="submit"
                            onClick={() => {
                                handleSaveMatchAndStatistics();
                                setIsButtonVisible(false); // Butonu gizle
                            }}
                        >
                            Maçı ve Oyuncu İstatistiklerini Kaydet
                        </button>
                    )}

                </div>
            )}


        </div>
    );
};

export default Add_Match;
