import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Editor_Leagues.css';

const Editor_Leagues = () => {
    const [leagues, setLeagues] = useState([]);
    const [newLeague, setNewLeague] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Mevcut ligleri getir
    useEffect(() => {
        const fetchLeagues = async () => {
            try {
                const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler");
                setLeagues(response.data);
            } catch (error) {
                console.error('Error fetching leagues:', error);
            }
        };
        fetchLeagues();
    }, []);

    // Yeni lig ekleme fonksiyonu
    const handleAddLeague = async () => {
        if (newLeague.trim() === '') {
            setErrorMessage('Lig adı boş olamaz.');
            return;
        }

        try {
            await axios.post("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler", { league_name: newLeague });
            setNewLeague('');
            setErrorMessage('');
            // Yeni ligleri yeniden çek
            const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler");
            setLeagues(response.data);
        } catch (error) {
            if (error.response?.status === 400) {
                setErrorMessage('Bu isimde bir lig zaten var.');
            } else {
                console.error('Error adding league:', error);
            }
        }
    };

    // Lig silme fonksiyonu
    const handleDeleteLeague = async (leagueId) => {
        const confirmDelete = window.confirm("Ligi silmek istediğinize emin misiniz?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler/${leagueId}`);
            setLeagues(leagues.filter((league) => league.id !== leagueId));
        } catch (error) {
            console.error('Error deleting league:', error);
            setErrorMessage("Lig silinirken bir hata oluştu.");
        }
    };

    return (
        <div className="leagues-container">
            {/* Mevcut Ligler Tablosu */}
            <div className="existing-leagues">
                <h2 className="section-title">Mevcut Ligler</h2>
                <div className="leagues-list">
                    {leagues.map((league) => (
                        <div key={league.id} className="league-item">
                            <span>{league.league_name}</span>
                            <button
                                className="delete-button"
                                onClick={() => handleDeleteLeague(league.id)}
                            >
                                Sil
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            {/* Yeni Lig Ekle Bölümü */}
            <div className="add-league-section">
                <h2 className="section-title">Yeni Lig Ekle</h2>
                <input
                    type="text"
                    value={newLeague}
                    onChange={(e) => setNewLeague(e.target.value)}
                    placeholder="Lig adı girin..."
                    className="league-input"
                />
                <button onClick={handleAddLeague} className="add-button">
                    Oluştur
                </button>

                {/* Hata ve Başarı Mesajları */}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
        </div>
    );
};

export default Editor_Leagues;
