import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';

const PlayerList = () => {
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const response = await axios.get('https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncular');
                setPlayers(response.data);
            } catch (err) {
                setError('Error fetching player data');
            } finally {
                setLoading(false);
            }
        };

        fetchPlayers();
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const playersPerPage = 20;

    const lastIndex = currentPage * playersPerPage;
    const firstIndex = lastIndex - playersPerPage;

    const currentPlayers = players.slice(firstIndex, lastIndex);

    const totalPages = Math.ceil(players.length / playersPerPage);

    const getPaginationRange = () => {
        const range = [];
        const maxVisiblePages = 3;
        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                range.push(i);
            }
            return range;
        }
    
        let start = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let end = Math.min(totalPages, start + maxVisiblePages - 1);
    
        if (end === totalPages) {
            start = Math.max(1, totalPages - maxVisiblePages + 1);
        }
    
        for (let i = start; i <= end; i++) {
            range.push(i);
        }
    
        // Adjust for start > 1, and ensure '...' starts after the first element (only when necessary)
        if (start > 2) {  // Changed condition to `> 2` to avoid showing `1 ... 2`
            range.unshift('...');
            range.unshift(1);
        } else if (start === 2) {
            range.unshift(1);
        }
    
        // Adjust for end < totalPages and add '...' at the end
        if (end < totalPages - 1) {
            range.push('...');
            range.push(totalPages);
        } else if (end === totalPages - 1) {
            range.push(totalPages);
        }
    
        return range;
    };

    const paginationRange = getPaginationRange();

    return (
        <div>
            <Header />
            <header class="header-banner">
                <h1 class="banner-title">Oyuncu Listesi</h1>
                <div class="zigzag">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#4facfe" fill-opacity="1" d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,96C672,128,768,192,864,213.3C960,235,1056,213,1152,213.3C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                    </svg>
                </div>
            </header>
            <br></br>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                <table style={tableMatchesStyle}>
                    <thead>
                        <tr style={{ textAlign: 'center', borderBottom: '2px solid #ccc' }}>
                            <th style={{ ...textStyleBold, padding: '10px', width: '5%' }}>SIRA</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '20%' }}>OYUNCU</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '20%' }}>TAKIM</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '5%' }}>OM</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '5%' }}>GAL%</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '5%' }}>BER%</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '5%' }}>MAĞ%</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '5%' }}>SK</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '5%' }}>KK</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '6%', whiteSpace: 'nowrap' }}>M.B. GOL</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '6%', whiteSpace: 'nowrap' }}>M.B. PUAN</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '5%' }}>GOL</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '6%' }}>DEĞERİ</th>
                            <th style={{ ...textStyleBold, padding: '10px', width: '5%' }}>PUAN</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPlayers.map((player, index) => (
                            <tr key={index} style={{ textAlign: 'center', borderBottom: '1px solid #ddd' }}>
                                <td style={{ ...textStyleBold, padding: '10px', width: '5%' }}>{index + 1}</td>
                                <td style={{ ...textStyleBold, padding: '10px', width: '20%', textAlign: 'left', whiteSpace: 'nowrap' }}>
                                    <a href={`/oyuncular/${player.player_name}`} style={{ whiteSpace: 'nowrap', textDecoration: 'none', color: 'inherit' }}>
                                        <img src={player.player_img} alt={player.player_name} style={{ width: '20px', marginRight: '10px', verticalAlign: 'middle' }} />
                                        {player.player_name}
                                    </a>
                                </td>
                                <td style={{ ...textStyleBold, color:'#430473',padding: '10px', width: '20%', textAlign: 'left', whiteSpace: 'nowrap' }}>
                                    <a href={`/takimlar/${player.team_name}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {player.team_name}
                                    </a>
                                </td>
                                <td style={{ ...textStyleBold, padding: '10px', width: '5%' }}>{player.total_matches}</td>
                                <td style={{ ...textStyleBold, padding: '10px', width: '5%' }}>{((player.wins / player.total_matches) * 100).toFixed(2)}</td>
                                <td style={{ ...textStyleBold, padding: '10px', width: '5%' }}>{((player.draws / player.total_matches) * 100).toFixed(2)}</td>
                                <td style={{ ...textStyleBold, padding: '10px', width: '5%' }}>{((player.losses / player.total_matches) * 100).toFixed(2)}</td>
                                <td style={{ ...textStyleBold, padding: '10px', width: '5%' }}>{player.total_yellow_cards}</td>
                                <td style={{ ...textStyleBold, padding: '10px', width: '5%' }}>{player.total_red_cards}</td>
                                <td style={{ ...textStyleBold, padding: '10px', width: '6%' }}>{(player.total_goals / player.total_matches).toFixed(2)}</td>
                                <td style={{ ...textStyleBold, padding: '10px', width: '6%' }}>{(player.total_points / player.total_matches).toFixed(2)}</td>
                                <td style={{ ...textStyleBold, padding: '10px', width: '5%' }}>{player.total_goals}</td>
                                <td style={{ ...textStyleBold, padding: '10px', width: '6%' }}>{player.value}</td>
                                <td style={{ ...textStyleBold, padding: '10px', width: '5%' }}>{player.total_points}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div style={{ textAlign: 'center', marginTop: '-10px' }}> {/* Adjusted marginTop here */}
                {paginationRange.map((page, index) => (
                    <button
                        key={index}
                        onClick={() => {
                            if (typeof page === 'number') {
                                setCurrentPage(page);
                            }
                        }}
                        disabled={page === '...'}
                        style={{
                            margin: '0 2px',
                            padding: '5px 10px',
                            height: '30px',
                            marginBottom: '10px',
                            backgroundColor: currentPage === page ? '#430473' : '#dfcdec',
                            color: currentPage === page ? '#dfcdec' : '#430473',
                            border: '1px solid #430473',
                            borderRadius: '5px',
                            cursor: typeof page === 'number' ? 'pointer' : 'not-allowed',
                        }}
                    >
                        {page}
                    </button>
                ))}
            </div>
        </div>
    );
};

const imageStyle = {
    width: '100%',
    height: '250px',
    display: 'inline-block',
    textAlign: 'center',
    margin: '153px auto 0',
    objectFit: 'cover', // Cover the container
    objectPosition: 'center', // Center the image in the container
};

const tableMatchesStyle = {
    width: '78%',
    borderCollapse: 'collapse',
    backgroundColor: 'white',

    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '1px',
    boxSizing: 'border-box',
    display: 'block',
};


const textStyleBold = {
    fontStyle: 'Posterama 1927 SemiBold',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
};

export default PlayerList;
