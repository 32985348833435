import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './Results.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';


const Results = () => {
    const [matches, setMatches] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const navigate = useNavigate();
    const { leagueName } = useParams();
    const [activeTab, setActiveTab] = useState("fixture");

    useEffect(() => {
        const fetchMatches = async () => {
            try {
                const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar");
                const filteredMatches = response.data.filter(match => formatTeamNameForURL(match.league_name) === leagueName); // Filter matches
                setMatches(filteredMatches);
            } catch (error) {
                console.error("Hata:", error);
            }
        };
        fetchMatches();
        const fetchSeasons = async () => {
            try {
                const response = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar");
                const filteredSeasons = response.data.filter(season => formatTeamNameForURL(season.league_name) === leagueName); // Filter matches
                setSeasons(filteredSeasons);
            } catch (error) {
                console.error("Hata:", error);
            }
        };
        fetchSeasons();
    }, []);

    const [hoveredRow, setHoveredRow] = useState(null);

    const sortedMatches = matches.sort((a, b) => new Date(a.date) - new Date(b.date)).reverse();

    // Group matches by date
    const groupedMatches = sortedMatches.reduce((groups, match) => {
        const date = match.date;
        if (!groups[date]) groups[date] = [];
        groups[date].push(match);
        return groups;
    }, {});



    return (
        <div>
            <Header />
            <header class="header-banner">
                <h1 class="banner-title">Sonuçlar</h1>
                <div class="zigzag">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#4facfe" fill-opacity="1" d="M0,256L48,224C96,192,192,128,288,96C384,64,480,64,576,96C672,128,768,192,864,213.3C960,235,1056,213,1152,213.3C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                    </svg>
                </div>
            </header>

            <div className='centered-container'>
                <div className='centered-container-2'>
                    <div className="button-fixture">
                        {/* Fikstür Tabı */}
                        <select
                            className={activeTab === "fixture" ? "active" : ""}
                            onClick={() => setActiveTab("fixture")}
                        >
                            <option value="fixture">Fikstür</option>
                        </select>
                    </div>

                    {/* Sezon Seçim Dropdown */}
                    <select
                        className={activeTab === "season" ? "active" : ""}
                        onClick={(e) => setActiveTab(e.target.value)}
                    >
                        <option value="">Sezon Seç</option>
                        {seasons.map((season) => (
                            <option key={season.id} value={season.season_name}>
                                {season.season_name}
                            </option>
                        ))}
                    </select>
                </div>


                <div className="tab-content">
                    {activeTab === "fixture" && (
                        <MatchList
                            matches={matches.filter((match) => match.is_it_fixture === true)}
                            scoreTime={false}
                        />
                    )}

                    {seasons.length > 0 ? (
                        seasons.map((season) =>
                            activeTab === season.season_name ? (
                                <MatchList
                                    key={season.id}
                                    matches={matches.filter((match) => (match.match_season === season.season_name) && (match.is_it_fixture === false))}
                                    scoreTime={true}
                                />
                            ) : null
                        )
                    ) : (
                        <p>Sezon bilgisi bulunamadı.</p>
                    )}
                </div>

            </div>
            <Footer />
        </div>
    );
};
function formatTeamNameForURL(teamName) {
    return teamName
        .toLowerCase()
        .replace(/ç/g, "c")
        .replace(/ğ/g, "g")
        .replace(/ı/g, "i")
        .replace(/ö/g, "o")
        .replace(/ş/g, "s")
        .replace(/ü/g, "u")
        .replace(/[^a-z0-9 ]/g, "")
        .trim()
        .replace(/\s+/g, "-");
}
const formatTime = (timeString) => {
    const time = timeString.slice(0, 5);
    return `${time}`;
};
function MatchList({ matches, scoreTime }) {
    const groupedMatches = matches.reduce((acc, match) => {
        const matchDate = new Date(match.date).toLocaleDateString("tr-TR", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });

        if (!acc[matchDate]) {
            acc[matchDate] = [];
        }
        acc[matchDate].push(match);
        return acc;
    }, {});

    return (
        <div className="match-list">
            <h3>{scoreTime ? "Yapılan Maçlar" : "Yapılacak Maçlar"}</h3>
            <div>
                <table className="match-table2">
                    <thead>
                        <tr>
                            <th style={{ borderTopLeftRadius: "10px" }}>Sonuçlar</th>
                            <th>
                                {scoreTime ? "Saat" : "Lig"}
                            </th>
                            <th style={{ borderTopRightRadius: "10px" }}>Açıklama</th>
                        </tr>
                    </thead>
                    {Object.entries(groupedMatches).map(([date, matchList]) => (
                        <tbody key={date}>
                            <tr>
                                <td colSpan="5" className="date-header" style={{ fontWeight: "bold", fontSize: "16px", backgroundColor: "#f0f0f0", textAlign: "left" }}>
                                    {date}
                                </td>
                            </tr>
                            {matchList.map((match, index) => (
                                <tr key={index}>
                                    <td style={{ textAlign: "center" }}>
                                        <div className="match-result">
                                            <span className="team-name2" onClick={() => window.location.href = `/takimlar/${formatTeamNameForURL(match.first_team_name)}`}>{match.first_team_name}</span>
                                            <span className="match-score">
                                                {scoreTime
                                                    ? `${match.first_team_score} - ${match.second_team_score}`
                                                    : formatTime(match.time)}
                                            </span>
                                            <span className="team-name2" onClick={() => window.location.href = `/takimlar/${formatTeamNameForURL(match.second_team_name)}`}>{match.second_team_name}</span>
                                        </div>
                                    </td>
                                    <td onClick={() => window.location.href = `/${formatTeamNameForURL(match.league_name)}/${match.match_season}-sezon`}>
                                        {scoreTime ? formatTime(match.time) : match.league_name}
                                    </td>
                                    <td>
                                        {scoreTime ? match.match_title : '-'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    );
};

export default Results;
