import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Update_Team.css';

const UpdateTeam = ({ selectedTeam, onClose, refreshTeams }) => {
    const [updatedFields, setUpdatedFields] = useState({ ...selectedTeam });
    const [errorMessage, setErrorMessage] = useState('');
    const [logoFile, setLogoFile] = useState(null); // Logo dosyası için state tanımı
    const [leagues, setLeagues] = useState([]); // Leagues verisi için state
    const [seasons, setSeasons] = useState([]); // Seasons verisi için state

    useEffect(() => {
        const fetchLeaguesAndSeasons = async () => {
            try {
                const leaguesResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/ligler");
                const seasonsResponse = await axios.get("https://elit-lig-back-end-bc5730e96b98.herokuapp.com/sezonlar");
                setLeagues(leaguesResponse.data);
                setSeasons(seasonsResponse.data);
            } catch (error) {
                console.error("Error fetching leagues or seasons:", error);
                setErrorMessage('Ligler veya sezonlar alınamadı.');
            }
        };

        fetchLeaguesAndSeasons();
    }, []);

    // Güncelleme fonksiyonu
const handleUpdateTeam = async (field) => {
    if (field === 'logo') {
        await handleLogoUpdate();
        return;
    }

    if (updatedFields[field] === undefined || updatedFields[field] === null) return;

    try {
        if (field === 'team_name') {
            const newTeamName = updatedFields[field]; // Yeni takım adı
            const oldTeamName = selectedTeam.team_name; // Mevcut takım adı

            try {
                // Yeni takım adını kontrol et
                const teamExistsResponse = await axios.get(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/team_name/${newTeamName}`);
                if (teamExistsResponse.data) {
                    alert("Bu isimde bir takım zaten var.");
                    return; // Güncellemeyi iptal et
                }
            } catch (checkError) {
                if (checkError.response && checkError.response.status === 404) {
                    console.log("Yeni takım adı mevcut değil, güncelleme işlemine devam ediliyor...");
                } else {
                    console.error("Takım adı kontrol edilirken hata oluştu:", checkError);
                    alert("Takım adı kontrol edilirken bir hata oluştu.");
                    return;
                }
            }

            // Eğer 404 döndüyse ya da kontrol hatası alınmadıysa güncellemelere devam et
            try {
                console.log("eski ", oldTeamName, "yeni ", newTeamName);

                // Maçlardaki takım adını güncelle
                try {
                    await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/maclar/update-team-name`, {
                        oldTeamName,
                        newTeamName,
                    });
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        console.warn("Maçlar tablosu güncellenemedi (404):", error);
                    } else {
                        throw error;
                    }
                }

                // Oyuncu-takımları tablosunu güncelle
                try {
                    await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/oyuncu-takimlari/update-team-name`, {
                        oldTeamName,
                        newTeamName,
                    });
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        console.warn("Oyuncu-Takımları tablosu güncellenemedi (404):", error);
                    } else {
                        throw error;
                    }
                }

                // Teams tablosundaki takım adını güncelle
                try {
                    await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${selectedTeam.id}`, {
                        team_name: newTeamName,
                    });
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        console.warn("Takımlar tablosu güncellenemedi (404):", error);
                    } else {
                        throw error;
                    }
                }

                // Güncellenen takım adını state'e yansıt
                setUpdatedFields({ ...updatedFields, team_name: newTeamName });
                selectedTeam.team_name = newTeamName; // selectedTeam'i de güncelleyin
                console.log("Güncellenmiş: ", selectedTeam.team_name);

                alert('Takım bilgisi başarıyla güncellendi!');
                refreshTeams();
            } catch (updateError) {
                console.error("Güncelleme sırasında hata oluştu:", updateError);
                alert("Takım bilgisi güncellenirken bir hata oluştu.");
            }
        } else {
            // Diğer alanlar için direkt güncelleme
            try {
                await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/${selectedTeam.id}`, {
                    [field]: updatedFields[field],
                });
                alert('Takım bilgisi başarıyla güncellendi!');
                refreshTeams();
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    console.warn("Diğer alan güncellenemedi (404):", error);
                } else {
                    throw error;
                }
            }
        }
    } catch (error) {
        console.error("Error updating team:", error);
        setErrorMessage('Güncelleme işlemi başarısız.');
    }
};



    // Logo güncelleme fonksiyonu (Dosya yükleme)
    const handleLogoUpdate = async () => {
        if (!logoFile) return;

        const formData = new FormData();
        formData.append("logo", logoFile);

        try {
            await axios.patch(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/updateLogo/${selectedTeam.id}`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            alert("Logo başarıyla güncellendi!");
            refreshTeams();
            onClose();
        } catch (error) {
            console.error("Error updating logo:", error);
            setErrorMessage("Logo güncelleme işlemi başarısız.");
        }
    };


    // Takımı silme fonksiyonu
    const handleDeleteTeam = async () => {
        const confirmDelete = window.confirm("Bu takımı silmek istediğinize emin misiniz?");
        if (!confirmDelete) return;

        try {
            // Takımı sil
            await axios.delete(`https://elit-lig-back-end-bc5730e96b98.herokuapp.com/takimlar/delete/${selectedTeam.id}`);
            alert("Takım ve ilgili oyuncu kayıtları başarıyla silindi!");
            refreshTeams(); // Takım listesini güncelle
            onClose(); // Modal'ı kapat
        } catch (error) {
            console.error("Error deleting team:", error);
            setErrorMessage("Silme işlemi başarısız.");
        }
    };

    if (!selectedTeam) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2 className="modal-title">Takım Güncelle</h2>
                <span className="close-button-updateTeam" onClick={onClose}>×</span>

                <div className="modal-scrollable">
                    <div className="update-field">
                        <label className='logo-label'>Takım Adı:</label>
                        <input
                            className="short-input"
                            type="text"
                            value={updatedFields.team_name}
                            onChange={(e) => setUpdatedFields({ ...updatedFields, team_name: e.target.value })}
                        />
                        <button className="update-button" onClick={() => handleUpdateTeam('team_name')}>Güncelle</button>
                    </div>
                    {/* Attendence Date Güncelle */}
                    <div className="update-field">
                        <label className='logo-label'>Katılım Tarihi:</label>
                        <input
                            type="date"
                            value={updatedFields.attendence_date}
                            onChange={(e) => setUpdatedFields({ ...updatedFields, attendence_date: e.target.value })}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdateTeam('attendence_date')}>Güncelle</button>
                    </div>
                    {/* Logo Güncelle (Dosya Yükleme) */}
                    <div className="update-field">
                        <label className='logo-label'>Logo Güncelle:</label>
                        <input
                            type="file"
                            onChange={(e) => setLogoFile(e.target.files[0])}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdateTeam('logo')}>Logo Güncelle</button>
                    </div>

                    <div className="update-field">
                        <label className='logo-label'>Lig Seçin:</label>
                        <select
                            className="short-input"

                            value={updatedFields.current_league}
                            onChange={(e) => setUpdatedFields({ ...updatedFields, current_league: e.target.value })}
                        >
                            <option value="">Lig Seç</option>
                            {leagues.map((league) => (
                                <option key={league.id} value={league.league_name}>
                                    {league.league_name}
                                </option>
                            ))}
                        </select>
                        <button className="update-button" onClick={() => handleUpdateTeam('current_league')}>Güncelle</button>
                    </div>

                    <div className="update-field">
                        <label className='logo-label'>Sezon Seçin:</label>
                        <select
                            className="short-input"

                            value={updatedFields.current_season}
                            onChange={(e) => setUpdatedFields({ ...updatedFields, current_season: e.target.value })}
                        >
                            <option value="">Sezon Seç</option>
                            {seasons.map((season) => (
                                <option key={season.id} value={season.season_name}>
                                    {season.season_name}{"  "}{season.league_name}
                                </option>
                            ))}
                        </select>
                        <button className="update-button" onClick={() => handleUpdateTeam('current_season')}>Güncelle</button>
                    </div>

                    {/* Toplam Maç Sayısı Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Toplam Maç Sayısı:</label>
                        <input
                            type="text" // Text tipi input
                            value={updatedFields.total_matches !== undefined ? updatedFields.total_matches : ''}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                // Boş değer ya da sayı kontrolü
                                const value = inputValue === '' ? 0 : isNaN(parseInt(inputValue, 10)) ? updatedFields.total_matches : parseInt(inputValue, 10);
                                setUpdatedFields({ ...updatedFields, total_matches: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdateTeam('total_matches')}>Güncelle</button>
                    </div>

                    {/* Galibiyetler Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Galibiyetler:</label>
                        <input
                            type="text"
                            value={updatedFields.team_wins !== undefined ? updatedFields.team_wins : ''}
                            onChange={(e) => {
                                const value = e.target.value === '' ? 0 : isNaN(parseInt(e.target.value, 10)) ? updatedFields.team_wins : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, team_wins: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdateTeam('team_wins')}>Güncelle</button>
                    </div>

                    {/* Beraberlik Sayısı Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Beraberlikler:</label>
                        <input
                            type="text"
                            value={updatedFields.team_draws !== undefined ? updatedFields.team_draws : ''}
                            onChange={(e) => {
                                const value = e.target.value === '' ? 0 : isNaN(parseInt(e.target.value, 10)) ? updatedFields.team_draws : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, team_draws: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdateTeam('team_draws')}>Güncelle</button>
                    </div>

                    {/* Mağlubiyet Sayısı Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Mağlubiyetler:</label>
                        <input
                            type="text"
                            value={updatedFields.team_losses !== undefined ? updatedFields.team_losses : ''}
                            onChange={(e) => {
                                const value = e.target.value === '' ? 0 : isNaN(parseInt(e.target.value, 10)) ? updatedFields.team_losses : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, team_losses: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdateTeam('team_losses')}>Güncelle</button>
                    </div>

                    {/* Atılan Goller Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Atılan Goller:</label>
                        <input
                            type="text"
                            value={updatedFields.goals_scored !== undefined ? updatedFields.goals_scored : ''}
                            onChange={(e) => {
                                const value = e.target.value === '' ? 0 : isNaN(parseInt(e.target.value, 10)) ? updatedFields.goals_scored : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, goals_scored: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdateTeam('goals_scored')}>Güncelle</button>
                    </div>

                    {/* Yenilen Goller Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Yenilen Goller:</label>
                        <input
                            type="text"
                            value={updatedFields.goals_conceded !== undefined ? updatedFields.goals_conceded : ''}
                            onChange={(e) => {
                                const value = e.target.value === '' ? 0 : isNaN(parseInt(e.target.value, 10)) ? updatedFields.goals_conceded : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, goals_conceded: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdateTeam('goals_conceded')}>Güncelle</button>
                    </div>

                    {/* Takım Puanı Güncelle */}
                    <div className="update-field">
                        <label className="logo-label">Takım Puanı:</label>
                        <input
                            type="text"
                            value={updatedFields.team_points !== undefined ? updatedFields.team_points : ''}
                            onChange={(e) => {
                                const value = e.target.value === '' ? 0 : isNaN(parseInt(e.target.value, 10)) ? updatedFields.team_points : parseInt(e.target.value, 10);
                                setUpdatedFields({ ...updatedFields, team_points: value });
                            }}
                            className="short-input"
                        />
                        <button className="update-button" onClick={() => handleUpdateTeam('team_points')}>Güncelle</button>
                    </div>




                    {/*<button className="update-team-delete-button" onClick={handleDeleteTeam}>Takımı Sil</button>*/}
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
        </div>
    );
};

export default UpdateTeam;
